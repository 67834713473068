.docs-popover-example-scroll {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 800px;
}
.bp3-popover.bp3-popover-content-sizing {
  border-radius: 4px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.17);
  .bp3-popover-arrow-border,
  .bp3-popover-arrow-fill {
    fill: $rt-light-gray4;
  }
  .bp3-popover-content {
    max-width: 300px;
    padding: 24px;
    border-radius: 4px;
    background: $rt-light-gray4;
    h6 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: $oxford-blue3;
    }
    * {
      color: $black;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }
}
.bp3-popover .bp3-popover-arrow::before:not(.bp3-tooltip) {
  box-shadow: none;
}
@media only screen and (max-width: 480px) {
  .docs-popover-example-scroll {
    width: 340px;
  }
}
