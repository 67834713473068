.bp3-tabs {
  .bp3-tab[aria-selected='true'] {
    color: $oxford-blue3;
  }
  .bp3-tab {
    background: $white;
    color: $oxford-blue3;
  }
  &.bp3-vertical {
    .bp3-tab-list .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'] {
      box-shadow: inset 1px 0 0 #302f2f !important;
    }
    &.tabs-standard {
      .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'],
      .bp3-large .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'],
      .bp3-large .bp3-tab[aria-selected='false'] {
        box-shadow: inset 2px 0 0 $gray10 !important;
      }
    }
    & > .bp3-tab-list .bp3-tab[aria-selected='true']:hover {
      background: $minimal-button-background-color-hover;
      color: $oxford-blue3;
    }
    .bp3-tab-list .bp3-tab[aria-selected='true'] {
      box-shadow: inset 2px 0px 0px $oxford-blue3;
      padding: 6px 32px;
    }
    .bp3-tab-list .bp3-tab[aria-selected='false'] {
      box-shadow: inset 2px 0px 0px $gray10;
      padding: 6px 32px;
    }

    &.tabs-standard {
      .bp3-tab {
        background-color: $rt-light-gray4 !important;
      }
    }
    .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'] {
      box-shadow: none !important;
    }
    & > .bp3-tab-list {
      .bp3-tab-indicator-wrapper .bp3-tab-indicator {
        background-color: transparent;
        box-shadow: inset 2px 0px 0 $oxford-blue3;
      }
      .bp3-tab[aria-selected='true'] {
        background-color: transparent;
        background: $white;
      }
    }
    & > .bp3-tab-list {
      &.bp3-large {
        .bp3-tab {
          padding: 6px 32px;
        }
      }
      .bp3-tab {
        padding: 11px 12px;
        &::before {
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: -1px;
          position: absolute;
          border-width: 1px 1px 1px 0;
        }
        &:last-child::before {
          bottom: 0;
        }
      }
    }
    .bp3-tab {
      &[aria-selected='false'] {
        box-shadow: none;
      }
      &[aria-disable='false'] {
        color: #000000;
      }
      &[aria-disable='true'] {
        color: #b2b2b2;
      }
    }
    & > .bp3-tab-list {
      &.bp3-large {
        .bp3-tab[aria-selected='true'] {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-left: 2px solid $oxford-blue3;
          }
        }
      }
      .bp3-tab-indicator-wrapper ~ .bp3-tab::after {
        display: none;
      }
    }
  }
  .bp3-tab {
    &:not([aria-disabled='true']):hover {
      color: $oxford-blue3;
      background: $minimal-button-background-color-hover !important;
    }
    &:focus-visible {
      outline: none;
      border: 2px solid rgba(19, 124, 189, 0.6);
    }
    &-list {
      &.bp3-large {
        & > div {
          padding: 6px 32px;
          height: 60px;
        }
      }
      & > div {
        margin-right: 0;
        padding: 6px 32px;
        font-size: 16px;
        line-height: 24px;
        height: 48px;
        display: flex;
        align-items: center;
      }
    }
    &[aria-disabled='true'] {
      color: #b2b2b2;
    }
  }
  &.tabs-standard:not(.bp3-vertical) {
    .bp3-tab-list > *:not(.bp3-tab-indicator-wrapper) {
      background: #f5f5f5;
      padding: 6px 32px;
    }
  }
  .bp3-tab-indicator-wrapper {
    z-index: 1;
  }
  .bp3-tab[aria-selected='true'] {
    box-shadow: 0px 2px 0px $oxford-blue3;
  }
  .bp3-tab[aria-selected='false'] {
    box-shadow: 0px 2px 0px $gray10;
  }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    height: 2px;
    background-color: $oxford-blue3;
  }
  .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'] {
    box-shadow: inset 0 -2px 0 #e0e0e0 !important;
  }
  .bp3-large {
    .bp3-tab[aria-selected='true'] {
      box-shadow: 0px 2px 0px $oxford-blue3;
    }
    .bp3-tab[aria-selected='false'] {
      box-shadow: 0px 2px 0px $gray10;
    }
    .bp3-tab-indicator-wrapper .bp3-tab-indicator {
      height: 3px;
    }
    .bp3-tab-indicator-wrapper ~ .bp3-tab[aria-selected='false'] {
      box-shadow: inset 0 -3px 0 #e0e0e0 !important;
    }
  }
}
