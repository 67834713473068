// Gray scale
$black: #000000 !default;

$rt-dark-gray1: #151515 !default;
$rt-dark-gray2: #292929 !default;
$rt-dark-gray3: #333333 !default;
$rt-dark-gray4: #3d3d3d !default;

$gray1: #4a4949 !default;
$gray2: #565656 !default;
$gray3: #636262 !default;
$gray4: #706f6f !default;
$gray5: #7d7c7c !default;
$gray6: #8b8b8b !default;
$gray7: #959595 !default;
$gray8: #9e9e9e !default;
$gray9: #a8a8a8 !default;
$gray10: #b2b2b2 !default;

$rt-light-gray1: #d6d6d6 !default;
$rt-light-gray2: #e0e0e0 !default;
$rt-light-gray3: #ebebeb !default;
$rt-light-gray4: #f5f5f5 !default;

$white: #ffffff !default;

// core color

// success
$green-pantone1: #006524 !default;
$green-pantone2: #007e2e !default;
$green-pantone3: #009837 !default;
$green-pantone4: #00b140 !default;
$green-pantone5: #00cb49 !default;

// warning

$princeton-orange1: #b75c00 !default;
$princeton-orange2: #d16900 !default;
$princeton-orange3: #ea7600 !default;
$princeton-orange4: #ff8304 !default;
$princeton-orange5: #ff8f1e !default;

// primary

$oxford-blue1: #001d3d !default;
$oxford-blue2: #002652 !default;
$oxford-blue3: #002c5f !default;
$oxford-blue4: #00397a !default;
$oxford-blue5: #00438f !default;

// danger

$rt-red1: #bc001d !default;
$rt-red2: #d80021 !default;
$rt-red3: #e60d2e !default;
$rt-red4: #eb3d58 !default;
$rt-red5: #ef6277 !default;

// minimal
$minimal-button-background-color: #ffffff !default;
$minimal-button-background-color-hover: #e5eaef !default;
$minimal-button-background-color-active: #e0e0e0 !default;

// blue
$blue1: #001d3d !default;
$blue2: #597697 !default;

// green
$green1: #006524 !default;
$green2: #00b140 !default;

// orange
$orange1: #b75c00 !default;
$orange2: #ff8304 !default;

// red
$red1: #c10b26 !default;
$red2: #f32b49 !default;

// Extended colors

$princeton-orange: #ea7600 !default;

$rubine-red: #d60057 !default;

$dark-magenta: #8c189b !default;

$fandago: #a9398d !default;

$ou-crimson-red: #8b0d19 !default;

$denim: #005eb8 !default;

$oxford-blue: #002c5f !default;

$cyan-process: #00b5e2 !default;

$keppel: #40c1ac !default;

$apple-green: #84bd00 !default;

$green-pantone: #00b140 !default;

$default-color: #f5f5f5 !default;

$button-gradient: none;

$button-intent-gradient: none;

$button-color-disabled: $white !default;

$bg-btn-ouline: #e5eaef !default;

$button-intent-color-disabled: $gray10 !default;
@import 'color-alias';
