textarea.bp3-input {
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 12px;
  box-shadow: none;
  border: 1px solid rgba(41, 41, 41, 0.16);
  box-sizing: border-box;
  &:focus {
		border: 1px solid $oxford-blue3;
  }
  &.bp3-active {
    box-shadow: none;
  }
  &[class*='bp3-intent'] {
    &:focus {
      box-shadow: none;
    }
  }
  &.bp3-intent-primary {
    box-shadow: none;
    border: 1px solid $rt-blue2;
  }
  &.bp3-intent-success {
    box-shadow: none;
    border: 1px solid $green-pantone3;
  }
  &.bp3-intent-warning {
    box-shadow: none;
    border: 1px solid $princeton-orange;
  }
  &.bp3-intent-danger {
    box-shadow: none;
    border: 1px solid $rt-red3;
  }
}
.bp3-input:focus, .bp3-input.bp3-active {
  box-shadow: none
}