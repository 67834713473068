.bp3-tree-root {
  background-color: #f5f5f5;
}


.bp3-tree-node-content:not(.bp3-tree-node-content-2, .bp3-tree-node-content-1) {
  padding: 5px 12px;
}
.bp3-tree-node-content:hover{
  background-color: $minimal-button-background-color-hover;
}

.bp3-tree-node-content-1, .bp3-tree-node-content-2{
  background: $white;
}
.bp3-tree-node-icon {
  margin-right: 8px;
}
.bp3-tree-node-label {
  color: $black;
  font-size: 14px;
  line-height: 22px;
}
.bp3-tree-node.bp3-disabled {
  .bp3-tree-node-caret,
  .bp3-tree-node-icon,
  .bp3-tree-node-label {
    color: rgba(178, 178, 178, 1);
  }
}
.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background: $oxford-blue1;
  .bp3-tree-node-label {
    color: #ffffff;
  }
}
.bp3-tree-node-caret {
  padding: 7px 8px 7px 7px;
}

.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard {
  color: $black;
}

.bp3-tree-node-list.bp3-tree-root .bp3-tree-node:focus{
  outline: 2px auto rgba(19,124,189,.6);
  outline-offset: 2px;
}