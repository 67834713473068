.bunker-table-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.body-light {
    background-color: #F5F5F5;
}

.body-dark {
    background-color: #121212;
    color: white !important;
}

.greyBgColor {
    td {
        background-color: #F5F5F5;
        color: black;
    }
}

.darkGreyBgColor {
    td {
        background-color: #2F2F2F;
        color: white;
    }
}

.blackBgColor {
    td {
        background-color: #0d1424;
        color: white;
    }
}

.whiteBgColor {
    td {
        background-color: #FFFFFF;
        color: black;
    }
}

th {
    padding: 16px;
}
.bunker-row {
    td:first-child {
        background-clip: content-box;
        padding: 0 0 0 16px;
    }
    
    td:last-child {
        margin-right: 16px;
        display: flex;
    }
}

.row-heading {
    td {
        font-weight: 700;
        padding-bottom: 11px;
        padding-top: 8px;
        margin-top: 8px;
        font-size: 16px;
    };
}