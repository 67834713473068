@mixin disable {
  background: rgb(255, 255, 255);
  color: $gray10;
  border: 1px solid $gray10;
}
@mixin hover {
  border-radius: 4px;
  // box-shadow: none;
  border: 1px solid $rt-blue2;
  background: #e5eaef;
  color: $rt-blue2;
}
.bp3-button {
  border-radius: 4px;
  line-height: 22px;
  text-align: center;
  &:not([class*='bp3-intent-']) {
    color: $oxford-blue3;
    &:hover {
      box-shadow: $button-box-shadow-hover;
    }
    &:active {
      box-shadow: $button-box-shadow-active;
    }
    &:disabled,
    &.bp3-disabled {
      box-shadow: $button-box-shadow;
      color: $gray10;
    }
  }

  &.bp3-intent-primary {
    background: $oxford-blue3;
    &:disabled,
    &.bp3-disabled {
      color: $white;
      background: rgb(178, 178, 178);
    }
    &:active,
    &.bp3-active {
      background: $blue1;
    }
  }
  &.bp3-loading {
    &.bp3-minimal {
      background: #e6e6e6 !important;
    }
    &.bp3-outlined {
      background: #e5e5e5 !important;
      border: 1px solid #b3bcc6 !important;
    }
    &:disabled,
    &.bp3-disabled {
      background: #b3bcc6;
      opacity: 0.8;
    }

    & > :not(.bp3-button-spinner) {
      visibility: unset;
    }
    &.no-text {
      .bp3-button-spinner {
        left: 9px;
      }
    }
  }
  &.bp3-outlined {
    border: 1px solid $rt-blue2;
    height: 32px;
    background: $white;
    box-shadow: none;
    .bp3-icon {
      color: $rt-blue2;
    }
    &:hover {
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid $rt-blue2;
      background: $bg-btn-ouline;
      color: $rt-blue2;
    }
    &:active,
    &.bp3-active {
      background: $rt-blue2;
      color: $white;
      border: 1px solid $rt-blue2;
      box-shadow: none;
      .bp3-icon {
        color: $white;
      }
    }
    &:disabled,
    &.bp3-disabled,
    &.bp3-disabled:hover {
      background: $white;
      box-shadow: none;
      @include disable();
    }
    &.bp3-intent-primary {
      border: 1px solid $rt-blue2;
      color: $rt-blue2;
      background: $white;
      &:active,
      &.bp3-active {
        background: $rt-blue2;
        color: $white;
        border: 1px solid $rt-blue2;
      }
      &:hover {
        background: $white;
        @include hover();
      }
      &:disabled,
      &.bp3-disabled {
        @include disable();
      }
    }
  }
  &.bp3-small {
    padding: 0 8px;
  }
  &.bp3-large {
    border-radius: 4px;
    line-height: 24px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.no-text {
    padding: 8px;
    &.bp3-large {
      padding: 14px;
      .bp3-button-spinner {
        left: 14px;
      }
      & :last-child {
        margin-right: 0;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &.has-icon {
    &.bp3-large {
      & > * {
        margin-right: 16px;
      }
      & :last-child {
        margin-right: 0;
      }
    }
  }
  &.align-load {
    &-left {
      .bp3-button-spinner {
        left: 10px;
      }
    }
    &-right {
      .bp3-button-spinner {
        right: 10px;
      }
    }
  }
  &.bp3-minimal {
    .bp3-icon {
      color: $oxford-blue3;
    }
    &:active,
    &.bp3-active {
      background: $minimal-button-background-color-active;
      color: $oxford-blue3;
      box-shadow: none;
    }
    &:hover {
      background: $minimal-button-background-color-hover;
    }
    &:not([class*='bp3-intent-']) {
      &:hover {
        box-shadow: none;
        color: $oxford-blue3;
        .bp3-icon {
          color: $oxford-blue3;
        }
      }
      &:disabled,
      &.bp3-disabled {
        box-shadow: none;
        background: rgb(255, 255, 255);
        color: $oxford-blue3;
        &:hover {
          box-shadow: none;
          background: rgb(255, 255, 255);
          color: $oxford-blue3;
        }
      }
    }
    &.bp3-intent-primary {
      background: $minimal-button-background-color;
      color: $oxford-blue3;
      .bp3-icon {
        color: $oxford-blue3;
      }
      &:hover {
        background: $minimal-button-background-color-hover;
        color: $oxford-blue3;
      }
      &:active,
      &.bp3-active {
        background: $minimal-button-background-color-active;
        color: $oxford-blue3;
      }
      &:disabled,
      &.bp3-disabled {
        box-shadow: none;
        background: $white;
        color: $gray10;
      }
    }
  }
  &:focus-visible {
    outline: 2px auto rgba(19, 124, 189, 0.6);
    outline-offset: 2px;
  }

  .bp3-spinner-animation {
    svg {
      path {
        fill: $oxford-blue3;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .bp3-button-group.bp3-large .bp3-button {
    width: 85px;
  }
}
