@import 'rt-variable';

.bp3-multi-select {
  &.bp3-large {
    .bp3-tag.bp3-large {
      margin-right: 12px;
    }
    .bp3-button {
      height: 24px;
      width: 24px;
    }
  }
  &.bp3-large {
    .bp3-tag-input-values {
      padding-top: 0px;
      .bp3-tag .bp3-fill {
        margin-right: 19px;
      }
    }
  }
  &.bp3-tag-input.bp3-large {
    .bp3-tag.bp3-round.bp3-large {
      padding: 5px 32px;
      margin-right: 12px;
    }
    .bp3-button {
      padding: 0;
      margin-top: 4px;
    }
    .bp3-tag-input-values > * {
      margin: 0;
      margin-bottom: 4px;
    }
  }
  .bp3-button.bp3-minimal:hover {
    background-color: #ffffff;
  }
  .bp3-tag .bp3-tag-remove {
    margin-right: 0 !important;
  }
  .bp3-tag-remove:focus {
    outline: none;
  }
  .bp3-tag-input-values {
    margin-left: 0;
    .bp3-tag .bp3-fill {
      padding: 0;
    }
  }
  &.bp3-input {
    background-color: #ffffff;
    padding: 4px 4px 0 12px;
    &.bp3-large {
      min-height: 48px;
    }
    &:focus,
    &.bp3-active {
      opacity: 1;
      border: 1px solid $oxford-blue3;
      &.bp3-tag-input .bp3-input-ghost::placeholder {
        color: $black;
      }
      .bp3-button.bp3-minimal .bp3-icon {
        svg {
          color: $black;
        }
      }
    }
  }
  .bp3-multi-select-tag-input-input::placeholder {
    color: rgba(41, 41, 41, 0.7);
    font-size: 14px;
  }
  &.bp3-large {
    .bp3-multi-select-tag-input-input::placeholder {
      color: rgba(41, 41, 41, 0.7);
      font-size: 16px;
    }
  }
  .bp3-button.bp3-minimal .bp3-icon {
    svg {
      color: $gray4;
    }
  }

  .bp3-tag::before,
  .bp3-tag > * {
    margin-right: 8px;
  }
  &.bp3-tag-input .bp3-input-ghost {
    width: 60px;
    font-size: 14px;
    color: $black;
  }
  .bp3-tag {
    padding: 1px 8px;
    min-width: 75px;
    height: 24px;
    font-size: 14px;
    &.bp3-large {
      height: 32px;
      font-size: 14px;
      padding: 0 6px 0 12px;
      margin-right: 8px;
      > span {
        max-width: 45px;
        margin-right: 8px;
      }
    }
    > span {
      max-width: 40px;
      margin-right: 2px;
    }
    & > :last-child {
      opacity: 1;
    }
  }
}
.select-input {
  &.bp3-button,
  &.bp3-button:hover,
  &.bp3-button:active {
    border: 1px solid $rt-gray2;
    background-color: #ffffff;
    box-shadow: none;
    outline: none;
    background: none;
    color: $rt-gray3;
  }
  &.bp3-button .bp3-icon {
    color: $rt-gray3;
  }
}

.bp3-popover-open .bp3-icon-caret-up {
  color: #005eb8;
}

.bp3-popover-wrapper {
  display: block;
    .bp3-input-group {
      .bp3-input:focus{
        border: 1px solid $oxford-blue3;
      }
      .bp3-input:focus::placeholder{
        color: $black;
      }
      .bp3-input:focus {
        ~ .bp3-input-action {
          .bp3-button.bp3-minimal .bp3-icon > svg:not([fill]) {
            fill: $black;
          }
        }
      }
      .bp3-input-action {
        .bp3-button.bp3-minimal:focus * {
          color: $black;
        }
      }
    }
    .bp3-popover-target.bp3-popover-open{
      .bp3-input-group {
        .bp3-input::placeholder{
          color: $black ;
        }
      }
    }

  .bp3-input-group {
    .bp3-input-action .bp3-button.bp3-minimal {
      padding: 8px 8px 8px 12px;
      margin: 0;
  }
    background-color: transparent;
  }
  .bp3-popover-target {
    display: block;
  }
  .bp3-button.bp3-minimal .bp3-icon.bp3-icon-caret-down {
    color: #706f6f;
  }
  .bp3-input:focus {
    border: 0.7px solid rgba(41, 41, 41, 0.16);
  }
  .bp3-input-group {
    &.bp3-disabled {
      opacity: 0.5;
    }
    &.bp3-large {
      .bp3-input-action .bp3-button.bp3-minimal.no-text {
        padding: 14px 8px 14px 12px;
        margin: 0;
    }
      .bp3-input {
        font-size: 16px;
        line-height: 24px;
        ::placeholder {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .bp3-input {
      color: $black;
      background: $white;
      border: 1px solid $rt-light-gray2;
      line-height: 22px;
      font-size: 14px;
    }
    ::placeholder {
      color: $gray4;
    }
  }
}
.bp3-select-popover {
  border-radius: 10px;
  box-shadow: none;
  &.bp3-popover:not(.bp3-tooltip) {
    .bp3-menu-item:hover {
      background: $minimal-button-background-color-hover;
    }
  }
  &.bp3-popover {
    .bp3-menu-item {
      padding: 5px 12px;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      background: $white;
    }
    .bp3-menu {
      padding: none;
      background-color: #ffffff;
      box-shadow: none;
      overflow-y: auto;
      overflow-x: -moz-hidden-unscrollable;
    }
  }
  &.bp3-large .bp3-menu-item {
    padding: 12px;
    line-height: 24px;
    font-size: 16px;
  }
  .bp3-popover-content {
    padding: 0;
  }
  .bp3-menu-item:hover {
    background: rgba(0, 94, 184, 0.08);
  }
}

.bp3-multi-select-popover {
  &.bp3-large {
    .bp3-icon {
      margin-left: 12px;
    }
  }
  .bp3-icon {
    margin-left: 8px;
  }
  &.bp3-popover .bp3-popover-content {
    background-color: transparent;
  }
  .bp3-menu-item > * {
    margin-right: 0;
  }
  &.bp3-popover.bp3-minimal {
    margin-top: 0px !important;
  }
  &.bp3-popover {
    box-shadow: none;
    .bp3-menu {
      border: 1px solid $rt-light-gray2;
      padding: 0px;
      background-color: #ffffff;
      box-shadow: none;
      overflow-y: auto;
      overflow-x: -moz-hidden-unscrollable;
      > .bp3-checkbox {
        line-height: 30px;
        margin: 0;
      }
    }
    .bp3-menu-item {
      padding: 5px 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: $black;
      font-size: 14px;
    }
    .bp3-menu-item:hover {
      background: $minimal-button-background-color-hover;
    }
  }
  &.bp3-large {
    .bp3-menu-item {
      padding: 12px;
      line-height: 24px;
      font-size: 16px;
    }
  }
  .bp3-menu-item {
    > .bp3-icon {
      order: 2;
      margin-top: 0;
      svg {
        color: $black;
      }
    }
    &:hover {
      background: rgba(0, 94, 184, 0.08);
    }
  }
}
.bp3-select-popover {
  .bp3-popover-content {
    background-color: transparent;
    border-radius: 0;
  }
  &.bp3-popover.bp3-minimal {
    margin-top: -0.2px !important;
  }
  .bp3-menu {
    padding: 4px;
    border-radius: 0;
    background-color: #ffffff;
    border: 0.7px solid rgba(41, 41, 41, 0.16);
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: -moz-hidden-unscrollable;
    box-shadow: none;
  }
}
.ant-table tr {
  border: 1px solid #ebebeb;
}
.bp3-select-popover li {
  background-color: #ffffff;
}
.bp3-tag-input.bp3-input.bp3-disabled .bp3-tag-input-values .bp3-tag {
  background: #B2B2B2;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bp3-tag-input .bp3-tag-input-values > * {
  margin: 0 12px 4px 0;
}
