.bp3-form-group {
    &.bp3-intent-primary {
        .bp3-numeric-input {
            border: 1px solid $rt-blue2;
        }
    }
    &.bp3-intent-success {
        .bp3-numeric-input {
            border: 1px solid $green-pantone3;
        }
    }
    &.bp3-intent-warning {
        .bp3-numeric-input {
            border: 1px solid $princeton-orange3;
        }
    }
    &.bp3-intent-danger {
        .bp3-numeric-input {
            border: 1px solid $rt-red3;
        }
    }
    &[class*='bp3-intent'] {
        &.bp3-disabled {
            .bp3-numeric-input {
                border: 1px solid $rt-gray2;
            }
        }
    }
}

.bp3-numeric-input {
    background: $white;
    border: 1px solid $rt-light-gray2;
    border-radius: 4px;
    height: 32px;
    padding: 5px 0px 5px 12px;
    align-items: center;
    &.bp3-large {
        height: 48px;
        padding: 5px 0px;
        font-size: 16px;
        line-height: 24px;
        .bp3-button {
            svg {
                width: 16px;
                height: 16px;
            }
        }
        .bp3-button-group.bp3-vertical>.bp3-button {
            width: auto;
            padding: 0 16px 0 12px;
        }
        .bp3-input {
            height: 32px;
            margin: 8px 0;
            padding: 0 0 0 16px;
        }
    }
    .bp3-button {
        svg {
            width: 12px;
            height: 12px;
        }
    }
    .bp3-button-group.bp3-vertical>.bp3-button {
        width: auto;
        min-width: auto;
        padding: 0 12px 0 8px;
    }
    .bp3-input {
        border: none;
        border-radius: 4px;
        font-size: 14px;
        line-height: 22px;
        height: auto;
        padding: 0;
        &:focus {
            border: none;
        }
    }
    >.bp3-input-group {
        flex-grow: 1;
        margin: -1px 0;
    }
    .bp3-button-group.bp3-vertical>.bp3-button:first-child {
        border-radius: 0 4px 0 0;
    }
    .bp3-button-group.bp3-vertical>.bp3-button:last-child {
        border-radius: 0 0 4px 0;
    }
    .bp3-button-group.bp3-vertical:first-child .bp3-button:first-child {
        border-radius: 4px 0 0 0;
    }
    .bp3-button-group.bp3-vertical:first-child .bp3-button:last-child {
        border-radius: 0 0 0 4px;
    }
    .bp3-button-group>.bp3-button {
        background-color: $white;
        background-image: unset;
        box-shadow: none;
        &:focus {
            outline: none;
            outline-offset: 0;
        }
        .bp3-icon {
            &:focus {
                outline: none;
                outline-offset: 0;
            }
        }
    }
    .bp3-input-group[class*='bp3-intent'] {
        .bp3-input {
            border: none;
        }
    }
}

.bp3-control-group:not(.bp3-vertical)>*:not(.bp3-divider) {
    margin-right: 0px;
}

.bp3-control-group> :first-child {
    border-radius: 4px 0 0 4px;
}

.bp3-control-group> :last-child {
    border-radius: 0 4px 4px 0;
}
.bp3-customise-numeric .bp3-numeric-input:focus-within {
    border: 1px solid $oxford-blue3;
}