$margin: 8px;

.rtcmd-button-select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -$margin / 2;

    .rtcmd-button-select-button {
        margin: $margin / 2;
        & > button {
            display: inline-block;
            font-size: 16px;
            padding: 0 16px;
            line-height: 32px;
            color: #005EB8;
            border-radius: 4px;
            background-color: #EBEBEB;
            border: none;
            cursor: pointer;

            &:focus {
                background-color: #002C5F;
                color: #EBEBEB;
            }

            &.selected {
                background-color: #005EB8;
                color: #EBEBEB;
            }
        }
    }
}
