.rtcmd-email {
  color: white;
  display: inline-block;
  max-width: 20vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bp3-navbar-group {
  height: 74px !important;
}

@media (max-width: 900px) {
  .rtcmd-email {
    display: none;
  }

  .bp3-navbar {
    padding: 0 !important;
  }

  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading {
    margin-right: 10px !important;
    height: 75px !important;
    margin: -1px;
  }
}

.nav_links::-webkit-scrollbar {
  display: none;
}

.nav_links {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bp3-navbar.dark_version {
  border: 1px solid #002c5f !important;
}

.ant-dropdown-menu {
  padding: 0 !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e5eaef !important;
}

.bp3-navbar .bp3-navbar-group ul li {
  margin-right: 10px !important;
}
