//ant table

//core components
@import "~rt-design-system-backup/src/assets/scss/core.scss";

@import "core/style.scss";

html,
body {
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  color: #000000;
}

.rtcmd--dialog--table {
  width: 100%;

  tr > th, tr > td {
    padding: 5px;
  }

  tr > th {
    text-align: left;
    font-weight: bold;
    background-color: #ebebeb;
  }

  td:last-child,
  th:last-child {
    text-align: right;
  }
}

.ant-table-content {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.13);
}

.ant-table-thead {
  .ant-table-cell {
    white-space: nowrap;
    overflow-wrap: nowrap;
  }
}

.ant-table-content .bp3-icon {
  margin: 0px 6px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.ant-table-row:hover td {
  background-color: #e5eaef !important;
}

hr {
  border: 0px solid transparent;
  border-bottom: 1px solid #e0e0e0;
}

// TODO: remove when typograhic styles become available

h1,
h2,
h3 {
  font-weight: bold;
}

h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
@media only screen and (min-height: 900px) {
  .trade-activity-table {
    height: 470px;
    max-height: 470px;
  }
  .marine-chart {
    max-height: 460px;
  }
}

@media only screen and (max-height: 900px) {
  .trade-activity-table {
    height: 340px;
    max-height: 340px;
  }
  .marine-chart {
    max-height: 330px;
  }
}

.market-info-drawer {
  .bp3-drawer-header {
    border-bottom: 1px solid white;
    .bp3-heading {
      color: white;
    }
    .bp3-button {
      background: black;
      border-color: white;
      .bp3-icon {
        svg {
          color: white;
        }
      }
    }
  }
}
.trading-view-tooltip {
  width: 200px;
  height: 90px;
  position: absolute;
  display: none;
  padding: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #131722;
  background-color: rgba(255, 255, 255, 1);
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 12px;
  pointer-events: none;
  border-radius: 10px;
}
.no_data_available_div {
  top: 50%;
  left: 30%;
  z-index: 5;
  position: absolute;
}

.trading-view-tooltip {
  width: 200px;
  height: 90px;
  position: absolute;
  display: none;
  padding: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #131722;
  background-color: rgba(255, 255, 255, 1);
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 12px;
  pointer-events: none;
  border: 1px solid rgba(33, 150, 243, 1);
  border-radius: 10px;
}
.trade-activity-table-card {
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #406acb;
  }
}

// Min height for select popover
.bp3-select-popover.bp3-popover .bp3-menu-item{
  min-height: 30px !important;
}