.rtcmd-main-content-wrapper {
  padding-top: 32px; // RT Navbar Height
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 0.1px;
  @media (max-width: 900px) {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.rtcmd-ffa-drawer-container {
  position: fixed;
  top: 76px;
  right: 0;
  height: calc(100vh - 76px);
  z-index: 5;

  .rtcmd-ffa-drawer-wrapper {
    padding: 32px;
    overflow-y: auto;
    overflow-x: hidden;
    .rtcmd-ffa-drawer-wrapper-control {
      text-align: right;
      margin-bottom: 24px;
    }

    h5 {
      margin-bottom: 16px;
    }

    .rtcmd-ffa-drawer-entry {
      &:not(:last-child) {
        margin-bottom: 22px;
      }

      border-bottom: 1px solid #ebebeb;

      .rtcmd-ffa-drawer-entry-header {
        font-size: 16px;
        line-height: 32px;
        color: #00438f;
        white-space: pre;

        span {
          color: #00438f;
        }
      }

      .rtcmd-ffa-drawer-info {
        font-size: 13px;
        line-height: 32px;
        display: flex;
        color: #636262;

        .rtcmd-ffa-drawer-flex-filler {
          flex: 1 1 0;
        }
      }
    }
  }
}
