.bp3-card {
    border-radius: 8px;
    &.bp3-elevation-0 {
        border: 1px solid rgba(41, 41, 41, 0.3);
    }
    &.bp3-elevation-1 {
        border: none;
    }
    &.bp3-elevation-2 {
        border: none;
    }
    &.bp3-elevation-3 {
        border: none;
    }
}