.bp3-tag {
  background-color: $rt-blue2;
  padding: 1px 8px;
  border-radius: 4px;
  height: 24px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  justify-content: space-between;
  color: $white;
  .bp3-fill {
    flex-grow: 0;
  }
  &.bp3-tag-remove {
    border-radius: 16px;
    opacity: unset;
  }

  &.bp3-extraLarge {
    height: 32px;
    padding: 5px 32px;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: $white;
    .bp3-icon {
      margin-right: 17px;
    }
    &.bp3-remove {
      padding: 4px 30px 4px 32px;
      .bp3-icon {
        margin-right: 0px;
      }
      .bp3-fill {
        margin-right: 14px;
      }
    }
  }

  &.bp3-large {
    height: 32px;
    padding: 5px 16px;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: $white;
    &.bp3-remove {
      padding: 4px 16px 4px 16px;
      .bp3-fill {
        margin-right: 6px;
      }
    }
  }
  &.bp3-round {
    border-radius: 32px;
    &.bp3-large {
      padding: 5px 16px;
    }
  }
  &.bp3-minimal {
    &.bp3-large {
      padding: 5px 16px;
    }
    &.bp3-intent-primary {
      background: $bg-minimal-primary;
      color: $oxford-blue1;
      .bp3-tag-remove {
        svg {
          path {
            fill: $rt-blue2;
          }
        }
      }
    }
    &.bp3-intent-success {
      background: $bg-minimal-success;
      color: $minimal-success;
      .bp3-tag-remove {
        svg {
          path {
            fill: $green-pantone;
          }
        }
      }
    }
    &.bp3-intent-warning {
      background: $bg-minimal-warning;
      color: $princeton-orange1;
      .bp3-tag-remove {
        svg {
          path {
            fill: $princeton-orange;
          }
        }
      }
    }
    &.bp3-intent-danger {
      background: $bg-minimal-danger;
      color: $rt-red-danger;
      .bp3-tag-remove {
        svg {
          path {
            fill: $rt-red3;
          }
        }
      }
    }
  }
  &.bp3-remove {
    padding: 1px 8px;
    border-radius: 16px;
    .bp3-fill {
      margin-right: 7px;
    }
    .bp3-tag-remove {
      opacity: 1;
      padding: 0;
      margin: 0;
      margin-right: 0 !important;
    }
    svg {
      path {
        fill: $white;
      }
    }
  }
  &.bp3-multiple {
    text-align: left;
    height: auto;
    .bp3-fill {
      flex: unset;
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
      word-break: break-all;
    }
  }
  &.bp3-intent-primary {
    background: $oxford-blue3;
  }
  &.bp3-intent-success {
    background: $green-pantone3;
  }
  &.bp3-intent-warning {
    background: $princeton-orange3;
  }
  &.bp3-intent-danger {
    background: $rt-red3;
  }
}
