.bp3-control-group {
  > *:first-child {
    width: 118px;
    .bp3-popover-wrapper{
      .bp3-popover-target.bp3-popover-open{
        .bp3-input-group {
          .bp3-input::placeholder{
            color: $white;
          }
        }
      }
    } 
    .bp3-input-group 
    {
      .bp3-input:focus ~ .bp3-input-action .bp3-button.bp3-minimal .bp3-icon > svg:not([fill]) {
        fill: $white;
      }
      .bp3-input {
        &:focus::placeholder{
          color: $white;
        }
        background-color: $rt-blue2;
        color: $white;
        border-radius: 4px 0 0 4px;
        &::placeholder {
          color: $white;
        }
      }
    }
    .bp3-input-group .bp3-input-action .bp3-button.bp3-minimal {
        &:focus * {
          color: $white;
        } 
        * {
          color: $white;
          background: $oxford-blue3;
        }
    }
  }
  .bp3-input-group{
    .bp3-input {
      border-radius: 0;
    }
  } 
  .bp3-button {
    border-radius: 0 4px 4px 0;
  }
  &.bp3-vertical {
    .bp3-input-group{
      .bp3-input {
        width: 144px;
      }
    } 
    > *:first-child {
      width: 144px;
      .bp3-popover-wrapper .bp3-input-group .bp3-input {
        border-radius: 4px 4px 0 0;
      }
    }
    > :last-child {
      border-radius: 0px 0px 4px 4px;
      width: 144px;
    }
    &.bp3-fill {
      width: 100%;
    }
  }
}
