@import "../../variables.scss";

.rtcmd-page-wrapper {
    h4 {
        font-size: 24px;
        line-height: 32px;
    }

    td {
        font-size: 14px;
        line-height: 22px;
    }
}

.rtcmd-page-header {
    font-weight: 700;
    display: flex;
    padding-bottom: 8px;
    flex-direction: row;
    flex-wrap: wrap;

    & > span {
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }

    h3 {
        font-size: 32px;
        line-height: 40px;
        display: inline;
    }

    .rtcmd-page-float-right {
        display: flex;
        flex: 0 1 auto;
        align-items: flex-start;
        flex-wrap: wrap;

        .bp3-input-group {
            width: 168px;
        }

        & > {
            span,
            button {
                margin-left: 12px;
                align-self: center;
            }
            span {
                display: flex;
            }
        }
    }

    .rtcmd-page-mobile-actions {
        width: 100%;

        & > div button,
        a {
            margin: 0;
            width: 100%;
            margin-top: 10px;
        }
    }

    .rtcmd-page-header-spacer {
        flex: 1 0 0;
    }

    .rtcmd-page-action-icon {
        width: 24px;
        height: 24px;
        text-align: center;
        color: #e5e5e5;
        background-color: $color-oxford-blue;
        border-radius: 12px;
        line-height: 24px;
        display: block;

        .bp3-icon {
            vertical-align: top;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.rtcmd-page-actions {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 32px;

    .rtcmd-page-tabs {
        flex: 1 0 auto;
    }
}

.for-correction-tooltip {
    display: inline;
    .bp3-popover-wrapper {
        display: inline;
        .bp3-popover-target {
            display: inline;
        }
    }
}

.ant-table tr {
    border: none !important;
}
