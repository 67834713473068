//blue
$rt-blue1: #005eb8;
$rt-blue2: #002c5f;

//red
$rt-red1: #e60d2e;

//gray
$rt-gray1: #ebebeb;
$rt-gray2: #cccccc;
$rt-gray3: #706F6F;

//white
$rt-white1: #F6F6F6;
$rt-white2: #F5F8FA;

