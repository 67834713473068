.bp3-popover:not(.bp3-tooltip) .bp3-datepicker .bp3-menu {
  border: none;
  .bp3-menu-item {
    font-size: 13px;
    color: #292929;
    &.bp3-active {
      color: #ffffff;
    }
  }
}

.bp3-popover {
  .bp3-popover-content {
    background: unset;
  }
  .bp3-datepicker {
    &.bp3-daterangepicker {
      .DayPicker-Day--outside {
        visibility: visible;
      }
      box-shadow: $pt-elevation-shadow-2;
      border-radius: 4px;
      &.bp3-daterangepicker-single-month {
        margin-top: 0px;
        box-shadow: $pt-elevation-shadow-2;
        border-radius: 4px;
        padding: 12px 0;
        background: $white;
      }
    }
    .DayPicker-Day:not(.DayPicker-Day--outside) {
      .bp3-datepicker-day-wrapper {
        color: $black;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .DayPicker-Weekday {
      font-size: 14px;
      line-height: 22px;
      color: $black;
    }
    .bp3-html-select select,
    .bp3-datepicker .bp3-select select {
      color: $black;
      font-size: 14px;
      line-height: 22px;
      padding: 0px 25px 0px 5px;
    }
    .bp3-datepicker-caption {
      .bp3-icon {
        color: $oxford-blue3;
        margin-left: 5px;
      }
    }
    .bp3-button {
      .bp3-icon {
        color: $oxford-blue3;
      }
    }
    .DayPicker-Day {
      font-size: 14px;
      line-height: 22px;
      &.DayPicker-Day--selected {
        .bp3-datepicker-day-wrapper {
          color: $white;
        }
      }
    }
  }
}

.bp3-popover:not(.bp3-tooltip) .bp3-datepicker .bp3-menu-item {
  padding: 4px 12px;
}
.bp3-customise-dateRangeInput {
  .bp3-popover-wrapper {
    .bp3-popover-open {
      .bp3-input-group:nth-child(1) {
        .bp3-input {
          border-width: 1px 0px 1px 1px;
          border-color: $oxford-blue3;
          border-style: solid;
        }
        .bp3-input-action {
          .bp3-icon-calendar {
            svg {
              fill: $oxford-blue3;
            }
          }
        }
      }
      .bp3-input-group:nth-child(2) {
        .bp3-input {
          border: 1px solid $oxford-blue3;
        }
        .bp3-input-action {
          .bp3-icon-calendar {
            svg {
              fill: $oxford-blue3;
            }
          }
        }
      }
    }
    .bp3-input-group {
      width: 195px;
      .bp3-input {
        border: 1px solid $rt-light-gray2;
        background: $white;
        align-items: center;
        color: $black;
      }
      &.bp3-disabled {
        .bp3-input {
          color: #b2b2b2;
        }
        .bp3-icon svg {
          color: $gray10;
        }
      }
      .bp3-input-action {
        .bp3-icon-calendar svg {
          fill: $gray4;
        }
      }
    }
    .bp3-input-group:nth-child(2) {
      .bp3-input {
        border-radius: 0px 4px 4px 0px;
      }
    }
    .bp3-input-group:nth-child(1) {
      .bp3-input {
        border-radius: 4px 0px 0px 4px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .bp3-customise-dateRangeInput .bp3-popover-wrapper .bp3-input-group {
    width: 171px;
  }
  .bp3-popover-content {
    .bp3-datepicker .DayPicker {
      max-width: 163px;
      min-width: 0;
    }
  }
  .bp3-popover .bp3-datepicker .bp3-html-select select,
  .bp3-popover .bp3-datepicker .bp3-datepicker .bp3-select select {
    font-size: 12px;
    line-height: 20px;
    padding: 0px 21px 0px 0px;
    margin-right: 26px;
  }
  .bp3-popover .bp3-datepicker .DayPicker-Weekday {
    font-size: 12px;
    line-height: 20px;
  }
  .bp3-datepicker .bp3-datepicker-day-wrapper {
    line-height: 20px;
  }
  .bp3-popover .bp3-datepicker .DayPicker-Day {
    font-size: 12px;
  }
  .bp3-popover .bp3-datepicker .DayPicker-Day:not(.DayPicker-Day--outside) .bp3-datepicker-day-wrapper {
    font-size: 12px;
    line-height: 20px;
  }
}
