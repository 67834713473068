// navbar
.bp3-navbar {
  box-shadow: none;
  padding: 0 16px 0 8px;
  border: 1px solid $rt-light-gray3;
  .bp3-navbar-group {
    .bp3-navbar-heading {
      margin-right: 24px;
      background-position: left center;
      background-repeat: no-repeat;
      height: 52px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 32px;
        font-size: 18px;
        line-height: 26px;
        a {
          color: $rt-blue2;
          font-weight: 400;
          text-decoration: none;
        }
        a:focus:not(:hover) {
          outline: 2px auto rgba(19, 124, 189, 0.6);
          outline-offset: 2px;
        }
        a:hover {
          border-bottom: 4px solid $rt-red1;
          padding: 22px 0;
        }
      }
      li:first-child {
        margin-left: 0;
      }
      li:last-child {
        margin-right: 0;
      }
    }
    &.nav-right {
      .user {
        background-repeat: no-repeat;
        background-position: right center;
        width: 48px;
        height: 48px;
        border-radius: 100px;
        margin-right: 12px;
      }
      .bp3-button {
        &.bp3-minimal {
          background: none;
          svg {
            width: 24px;
            height: 24px;
          }
          &:hover,
          &:active {
            background: none;
          }
          &.bp3-intent-primary,
          .bp3-icon {
            color: $denim;
          }
        }
      }
    }
  }
  &.light_version {
    background: $rt-light-gray4;
    border: 1px solid $white;
    .bp3-navbar-group {
      &.nav-right {
        .user {
          border: 1px solid $white;
        }
      }
    }
    &.bp3-fixed-top {
      border: none;
    }
  }
  &.dark_version {
    background: $oxford-blue;
    border: 1px solid $white;
    .bp3-navbar-group {
      ul {
        li {
          a {
            color: $white;
          }
        }
      }
      &.nav-right {
        .user {
          border: 1px solid #c2c2c2;
        }
        .bp3-button {
          &.bp3-minimal {
            &:hover,
            &:active {
              background: none;
            }
            &.bp3-intent-primary,
            .bp3-icon {
              color: $white;
            }
          }
        }
      }
    }
    &.bp3-fixed-top {
      border: none;
    }
  }
}
@media only screen and (max-width: 480px) {
  .bp3-navbar {
    display: flex;
    &.dark_version {
      height: 52px;
      border: none;
      padding: 0;
    }
    .bp3-navbar-group {
      &.bp3-align-right {
        position: absolute;
        right: 14px;
      }
      .bp3-navbar-heading {
        margin-right: 14px;
      }
      .menu {
        margin-right: 14px;
      }
      height: 52px;
      ul li {
        margin-right: 12px;
        &:last-child {
          margin-right: 12px;
        }
        a {
          font-size: 14px;
          line-height: 22px;
          &:hover {
            border-bottom: 4px solid #e60d2e;
            padding: 12px 0;
          }
        }
      }
      &.nav-mobile {
        .bp3-button {
          &.no-text {
            padding: 0;
            .bp3-icon {
              svg {
                height: 24px;
                width: 24px;
              }
              min-height: 16px;
              min-width: 16px;
            }
          }
          &.bp3-minimal {
            background: none;
            &:hover,
            &:active {
              background: none;
            }
            &.bp3-intent-primary,
            .bp3-icon {
              color: $white;
            }
          }
        }
      }
    }
  }
  .submenu {
    display: none;
  }
}
