@import "../../variables.scss";

.rtcmd-price-table {

    .rtcmd-price-table-header {
        display: flex;
        font-size: 20px;
        color: $color-oxford-blue;
        margin-bottom: 8px;

        .rtcmd-price-table-header-name {
            flex: 1 1 auto;
        }

        .rtcmd-price-table-header-icon {
            flex: 0 0 auto;
            cursor: pointer;
        }
    }

    .rtcmd-price-table-header-numbers {
        display: flex;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 8px;

        .rtcmd-price-table-header-number {
            flex: 0 0 auto;

            .rtcmd-price-table-header-number-header {
                margin-bottom: 4px;
                font-weight: bold;
            }
            .rtcmd-price-table-header-number-value {
                margin-bottom: 4px;
                font-weight: bold;
            }
        }

        .rtcmd-price-table-header-numbers-filler {
            flex: 1 1 0;

            & + .rtcmd-price-table-header-number {
                text-align: right;
            }
        }
    }

    .rtcmd-price-table-header-separator {
        height: 1px;
        background-color: #EBEBEB;
    }

    .rtcmd-price-table-table {
        width: 100%;
        table-layout: fixed;
        height: 100%;

        thead {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            
            td {
                padding-bottom: 1px;
            }
        }

        tbody {
            .rtcmd-price-table-table-row {
                &:nth-child(even) {
                    background-color: $color-table-nth-row;
                }

                td {
                    font-size: 14px;
                    line-height: 180%;
                    @media  only screen and (min-height: 900px) {
                        font-size: 16px;
                    }
                }
            }
        }

        thead, tbody {
            .rtcmd-price-table-table-row {
            }
        }
    }
}


