.rtcmd-stp-mapping-table {
  .ant-input {
    border-color: transparent !important;
    font: inherit !important;
    width: 80px;
    background-color: transparent !important;
    padding-left: 0;
  }
}
ant-tag {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 16px !important;
  line-height: 20px;
  white-space: nowrap;
  /* background: #fafafa; */
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
  border: none !important;
}
/* Add this to your CSS file */
.no-sort-icon .ant-table-column-sorter-inner {
  display: none !important;
}
