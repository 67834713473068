@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-LightItalic.woff2') format('woff2'), url('./fonts/RT_VickermanW01-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-Light.woff2') format('woff2'), url('./fonts/RT_VickermanW01-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RTVickermanW01-Condensed';
	src: url('./fonts/RTVickermanW01-Condensed.woff2') format('woff2'), url('./fonts/RTVickermanW01-Condensed.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-BoldItalic.woff2') format('woff2'), url('./fonts/RT_VickermanW01-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-Bold.woff2') format('woff2'), url('./fonts/RT_VickermanW01-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-Regular.woff2') format('woff2'), url('./fonts/RT_VickermanW01-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RT_VickermanW01';
	src: url('./fonts/RT_VickermanW01-Italic.woff2') format('woff2'), url('./fonts/RT_VickermanW01-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
