.rt-login {
    flex: 1 1 auto;
    background-image: url('../../../assets/rt-backdrop.jpg');
    background-size: cover;
    background-position: left center;
    min-height: 100vh;
    .rt-login-logo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    h3, h4 {
        margin-bottom: 1.4em;
        margin-top: 0;
    }

    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
    }

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
    }

    .rt-login-row {
        min-height: 100vh;
    }
    .login-btn{
        width: 100%;
    }

    .outline-btn {
        width: 100%;
        color: #002C5F
    }
    .arrow {
        font-size: 21px;
        margin-right: 10px;
        &:after  {content: ' \2192';}
    }

    .darkBtn{
        background-color: #002C5F !important;
    }

    .rt-login-button-group {
        display: flex;
        span {
            flex: 1 1 0;
        }
    }

    .rt-login-form-input {
        margin-bottom: 36px;
    }

    .rt-no-permissions-message {
        background-color: #F5F5F5;
        color: #002C5F;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .rt-no-permissions-message-p {
        padding: 2%;
    }
}

.rt-login-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;

    .rt-login-image {
        flex: 1 1 auto;
        background-image: url('../../../assets/rt-backdrop.jpg');
        background-size: cover;
        background-position: left center;
        min-height: 100vh;
    }
}
