.bp3-overlay-backdrop {
  background: rgba($color: #000000, $alpha: 0.6);
}
@media only screen and (max-width: 480px) {
  .bp3-overlay {
    .bp3-card {
      max-width: 300px;
      margin: 10vh 50px !important;
      p {
        line-height: 22px;
      }
    }
  }
}
