.rtcmd--view-order--header {
    display: flex;
    flex-direction: row;
    & > div:first-child {
        flex: 1 0 150px;
    }
    margin-bottom: 36px;
}

.rtcmd--view-order--badge {
    display: flex;
    border: 1px solid #ebebeb;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 32px;

    .rtcmd--view-order--badge--cell {
        flex: 1 0 100px;
        text-align: center;
        &:not(:last-child) {
            border-right: 1px solid #ebebeb;
        }

        & > div:first-child {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 180%;
            color: #636262;
        }

        & > div:last-child {
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 39px;
            color: #005eb8;
        }
    }
}

.rtcmd--view-order--pointer {
    background-color: #ebebeb;
    padding: 32px;
    margin-bottom: -32px;
    border-radius: 0 0 8px 8px;
    position: relative;

    &:before {
        content: " ";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 18px 0 18px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

hr {
    border: 0px transparent;
    border-top: 1px solid #EBEBEB;
    margin: 16px 0;
}
