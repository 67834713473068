.stp-api__right-action {
    &-box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 20px;
    }
    &-row {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }
    &-links {
        display: flex;
        align-items: center;
        gap: 10px;
        a {
            cursor: pointer;
            color: #002c5f;
        }
    }
}
.stp__api__viewing {
    margin-right: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
}
