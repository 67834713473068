.bp3-control {
  &.bp3-large {
    .bp3-control-indicator {
      width: 20px;
      height: 20px;
    }
  }

  &.bp3-radio {
    color: $black;
    .bp3-control-indicator {
      border: 2px solid $oxford-blue3;
    }
    input {
      &:not(:disabled) {
        &:checked {
          ~ {
            .bp3-control-indicator {
              background-color: $white;
              &::before {
                background-image: radial-gradient($rt-blue2, $rt-blue2 28%, transparent 32%);
              }
            }
          }
        }
      }
      &:disabled {
        ~ {
          .bp3-control-indicator {
            border: 2px solid $gray10;
            background: $white;
          }
        }
        &:checked {
          ~ {
            .bp3-control-indicator {
              background-image: none;
              background-color: $white;
            }
          }
        }
      }
      &:checked {
        &:disabled {
          ~ {
            .bp3-control-indicator {
              &::before {
                background-image: radial-gradient($gray10, $gray10 28%, transparent 32%);
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &:hover {
      input {
        &:checked {
          ~ {
            .bp3-control-indicator {
              background-color: unset;
            }
          }
        }
      }
    }
    &.bp3-align-right {
      .bp3-control-indicator {
        margin-top: 8px;
      }
    }
  }
}
