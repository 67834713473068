.rtcmd-orders-table {
    .ant-input {
        border-color: transparent !important;
        font: inherit !important;
        width: 80px;
        background-color: transparent !important;
        padding-left: 0;
    }
}
.dialog-title {
    border-bottom: 1px solid grey;
    padding-left: 8px;
    font-weight: 700;
}