// upload
.bp3-file-input {
  display: block;
  padding: 0px 0px 0px 12px;
  &.bp3-large {
    input {
      margin-top: 8px;
    }
  }
  .bp3-file-upload-input {
    font-size: 14px;
    line-height: 22px;
    padding: 5px 12px;
    color: $black;
    border-radius: 4px;
    border: 1px solid $rt-light-gray2;
    box-shadow: none;
    width: 322px;
    align-items: center;
    &::after {
      background: $oxford-blue3;
      border-radius: 4px;
      line-height: 22px;
      color: $white;
      background-image: none;
      font-size: 14px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      margin-left: 8px;
      box-shadow: none;
      //padding: 0 12px;
      margin: 0px;
      min-height: auto;
      padding: 5px 16px;
      width: 77px;
    }
    &:hover::after {
      background-color: $rt-blue2;
    }
  }
  &.bp3-large {
    .bp3-file-upload-input {
      font-size: 16px;
      line-height: 24px;
      padding: 12px;
      width: 446px;
    }
    .bp3-file-upload-input {
      &::after {
        font-size: 16px;
        line-height: 24px;
        margin: 0px;
        top: 0px;
        bottom: 0px;
        right: 0;
        width: 116px;
        padding: 12px 32px;
      }
    }
  }
}

.bp3-file-input input:disabled + .bp3-file-upload-input,
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
  background: $white;
  border: 0.7px solid #ccc;
  color: rgba(204, 204, 204, 0.7);
}

@media only screen and (max-width: 480px) {
  .bp3-file-input {
    &.bp3-large {
      .bp3-file-upload-input {
        width: 350px;
      }
    }
  }
}
