.rtcmd-chart {
    height: #{"m"}ax(50vh, 300px);
}

.rtcmd-chart-controls {
    display: flex;
    margin-bottom: 32px;
    margin-left: -12px;
    margin-right: -12px;
    & > div {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.rtcmd-chart-controls-filler {
    flex: 1 1 0;
}

.rtcmd-chart-shortcuts {
    padding-left: 8px;
    padding-right: 8px;
    button {
        margin-left: 4px;
        margin-right: 4px;
        padding-left: 16px;
        padding-right: 16px;
    }
}