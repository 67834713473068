$spacing-size: 16px;

.rtcmd--form--row--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -1 * $spacing-size / 2;
    & > div {
        flex: 1 0 200px;
        margin: $spacing-size / 2;
    }
}

.rtcmd--form--footer {
    margin-top: 2 * $spacing-size;
    text-align: right;

    button:not(:last-child) {
        margin-right: 8px;
    }
}
