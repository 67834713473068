.DoughnutChartLable {
    padding-top: 0;
    margin: 0;
    font-size: 13px;
    min-width: 40%;
}
.DoughnutChartPremium {
    padding-top: 0;
    margin: 0;
    font-size: 12px;
    min-width: 19%;
    padding-left: 5px;
    text-align: right;
}

.DoughnutChartPnl {
    padding-top: 0;
    margin: 0;
    font-size: 13px;
    min-width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
}

.DoughnutChartPercentage {
    background: #EBEBEB;
    text-align: center;
    border-radius: 3px;
    padding: 2px 2px 2px 2px;
    margin: 0;
    font-size: 10px;
    min-width: 10%
}

.premiumRow {
    display: flex;
    padding-left: 2px;
    align-items: center
}