.bp3-timepicker {
  .bp3-timepicker-divider-text {
    color: $oxford-blue3;
  }
  &.bp3-disabled {
    .bp3-timepicker-divider-text {
      color: $gray10;
    }
    .bp3-timepicker-input-row {
      background-color: #ffffff;
      opacity: 0.5;
    }
    .bp3-timepicker-arrow-button {
      color: $gray10;
      opacity: 0.5;
      &:hover {
        color: $gray10;
      }
    }
    .bp3-timepicker-input {
      color: $gray10;
    }
    .bp3-html-select {
      opacity: 0.5;
      .bp3-icon {
        color: $gray10;
      }
      select {
        background-color: #ffffff;
        color: $gray10;
        &:disabled {
          opacity: 1;
        }
      }
    }
  }
  .bp3-timepicker-ampm-select {
    margin-left: 8px;
    :focus-within {
      border: none;
      box-shadow: 0 0 0 1px $oxford-blue3;
    }
  }
  .bp3-html-select select {
    color: $black;
    border: 1px solid $rt-light-gray2;
    padding: 0 24px 0 10px;
    align-items: center;
    text-align: center;
  }
  .bp3-timepicker-input {
    height: 30px;
    color: $black;
    font-size: 14px;
    &:focus {
      box-shadow: none;
      border: none;
      box-shadow: 0 0 0 1px $oxford-blue3;
    }
    &-row {
      border: 1px solid $rt-light-gray2;
      box-shadow: unset;
      border-radius: 4px;
      box-sizing: border-box;
      padding: unset;
      height: 32px;
      line-height: 22px;
      text-align: center;
      align-items: center;
    }
  }
  .bp3-timepicker-input.bp3-intent-danger {
    -webkit-box-shadow: none;
  }
}
.bp3-html-select {
  .bp3-icon {
    color: $oxford-blue3;
  }
  select,
  .bp3-select select {
    background-color: $white;
    box-shadow: unset;
    border: 0.7px solid rgba(41, 41, 41, 0.16);
    border-radius: 4px;
    height: 32px;
    color: $oxford-blue3;
    &:hover {
      box-shadow: unset;
      background-clip: unset;
      background-color: $white;
    }
  }
}
.bp3-datepicker-year-select {
  min-width: auto;
}
