$margin-size: 24px;

.rt-form-row {
    display: flex;
    margin: ($margin-size * -1 * 0.5);
}

.rt-form-cell {
    padding: ($margin-size * 0.5);
    flex: 1 0 200px;
}

.rt-form-footer {
    text-align: right;
    margin-top: 16px;

    button:not(:last-child) {
        margin-right: 8px;
    }
}

.bp3-dialog.modal {
    .bp3-dialog-header {
        .bp3-heading {
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 39px;
            color: black;
        }
    }
}

.bp3-dialog .bp3-dialog-header .bp3-button .bp3-icon svg {
    height: 24px !important;
    width: 24px !important;
    margin-top: 12px;
    margin-right: 12px;
}

:global {
    // FIXME: remove if inconsistencies are fixed
    .bp3-multi-select.bp3-input {
        margin-bottom: none !important;
    }
}
