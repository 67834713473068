.bp3-alert {
  padding: 32px;
  .bp3-heading {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: $rt-blue2;
    margin-bottom: 16px;
    align-items: center;
  }
  * {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $black;
    margin: 0;
  }
  .bp3-alert-footer {
    margin-top: 16px;
    .bp3-button {
      font-weight: 400;
      background: $rt-light-gray4;
      box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.25);
      &:focus-visible {
        outline: 2px auto rgba(19, 124, 189, 0.6);
        outline-offset: 2px;
      }
      :last-child {
        align-items: center;
        color: $oxford-blue3;
        display: flex;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .bp3-alert {
    min-width: 300px !important;
    max-width: 300px;
  }
  .bp3-alert-contents p {
    font-size: 14px;
    line-height: 22px;
  }
}
