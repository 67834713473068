.bp3-breadcrumbs {
  .bp3-breadcrumbs-collapsed {
    background: unset;
    margin: 0 8px;
    padding: 0;
    &::before {
      background: url(../icon/breadcrumb.svg) 50% no-repeat;
      width: 32px;
      height: 32px;
    }
    &:hover {
      background: $rt-light-gray4;
      border-radius: 4px;
    }
    &:focus {
      outline: none;
      outline-offset: 0;
    }
    &:active {
      background: $rt-light-gray4;
      border-radius: 4px;
    }
    &.bp3-active {
      background: $rt-light-gray3;
      border-radius: 4px;
    }
  }
  .bp3-breadcrumb {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $oxford-blue3;
    padding: 0 12px;
    &.bp3-breadcrumb-current {
      color: $rt-dark-gray2;
      &:hover {
        color: $rt-dark-gray2;
        text-decoration: none;
      }
    }
    &.bp3-disabled {
      color: $gray10;
      &:hover {
        color: $gray10;
        text-decoration: none;
      }
    }
    &:hover {
      color: #3389db;
      text-decoration: none;
    }
  }
  > li:after {
    background: url(../icon/breadcrumb-icon.svg) !important;
    width: 8px;
    margin: 0;
  }
}
.bp3-popover:not(.bp3-tooltip) {
  box-shadow: unset;
  .bp3-menu {
    min-width: 120px;
    border: 1px solid $rt-light-gray2;
    border-radius: 4px;
    padding: 0;
  }
  .bp3-menu-item {
    font-size: 14px;
    line-height: 22px;
    color: $black;
    padding: 5px 12px;
    &:hover {
      background-color: $minimal-button-background-color-hover;
    }
  }
}
