.bp3-toast {
  background: $rt-light-gray4;
  border-radius: 4px;
  box-shadow: unset;
  min-width: 470px;
  max-width: 100%;
  height: 48px;
  .bp3-button-group {
    .bp3-button {
      background: none;
      .bp3-icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }
      :hover {
        background: none;
      }
    }
  }

  .bp3-toast-message {
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $oxford-blue3;
  }
  .bp3-button-group {
    line-height: 26px;
    padding: 0;
    .bp3-button {
      padding: 0;
      margin: 16px 19px 16px 0;
      min-width: 16px;
      min-height: 16px;
      .bp3-icon {
        color: $black;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .bp3-icon-standard {
        color: $rt-dark-gray4;
      }
      .bp3-icon-large {
        color: $rt-dark-gray4;
      }
    }
  }
  &.bp3-intent-primary {
    background: $bg-primary-toast;
    .bp3-toast-message {
      color: $black;
      padding: 12px;
    }
    .bp3-button-group {
      a.bp3-button {
        margin: 11px 15px 11px 0px;
        color: rgba(167, 182, 194, 1) !important;
      }
      .bp3-button {
        .bp3-icon {
          color: $black !important;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }
  &.bp3-intent-success {
    background: $bg-success-toast;
    .bp3-icon {
      color: $bg-icon-success-toast;
      margin: 18.5px 0px 18.5px 15px;
      margin-right: 0;
      svg {
        width: 14px;
        height: 11px;
      }
    }
    .bp3-toast-message {
      color: $black;
      padding: 12px;
    }
    .bp3-button-group {
      a.bp3-button {
        margin: 11px 15px 11px 0;
        color: rgba(221, 242, 231, 1) !important;
      }
      .bp3-button {
        .bp3-icon {
          color: $black !important;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }
  &.bp3-intent-warning {
    background: $bg-warning-toast;
    .bp3-icon {
      color: $bg-icon-warning-toast;
      margin: 14px 0 14px 12px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .bp3-toast-message {
      color: $black;
      padding: 12px;
    }
    .bp3-button-group {
      a.bp3-button {
        margin: 11px 15px 11px 0;
        color: rgba(244, 236, 225, 1) !important;
      }
      .bp3-button {
        .bp3-icon {
          color: $black !important;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }
  &.bp3-intent-danger {
    background: $bg-danger-toast;
    .bp3-icon {
      color: $bg-icon-danger-toast;
      margin: 16.8px 0 16.8px 14px;
      margin-right: 0;
      svg {
        width: 16px;
        height: 14.4px;
      }
    }
    .bp3-toast-message {
      color: $black;
      padding: 12px;
    }
    .bp3-button-group {
      a.bp3-button {
        margin: 11px 15px 11px 0;
        color: rgba(242, 224, 234, 1) !important;
      }
      .bp3-button {
        .bp3-icon {
          color: $black !important;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .bp3-toast {
    min-width: 350px;
    height: auto;
    .bp3-toast-message {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
