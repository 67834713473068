.stp-api-console-container {
  padding-bottom: 32px;
  min-height: 100vh;
  .header,
  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1,
    h2 {
      margin: 0;
      font-size: 24px;
      line-height: 32px;
    }
  }
å
  .details-title {
    font-size: 24px;
  }

  .form-content {
    width: 100%;
    margin: 0 auto;
    .bp3-form-group{
      width: 400px;
    }
   
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  
}

