.rtcmd-request-form-icon {
  &[data-key="0"] {
    opacity: 0;
    cursor: normal;
  }

  color: #00438f;
  display: block;
  align-self: center;
  cursor: pointer;
}
