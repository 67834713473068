// tag-input
.bp3-tag-input {
    &.bp3-active {
      border: 1px solid $oxford-blue3;
    }
    border: 1px solid $rt-light-gray2;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    padding: 4px 4px 0px 12px;
    position: relative;
    .bp3-button, .bp3-tag-input .bp3-spinner{
        margin: unset;
    }
    .bp3-button{
        width: 24px;
        height: 24px;
        &.bp3-minimal {
          .bp3-icon {
            color: $rt-gray3;
          }
        }
        &.bp3-minimal:hover {
          background: unset;
          color: $rt-gray3;
          .bp3-icon {
            color: $rt-gray3;
          }
        }
    }

    &.bp3-disabled {
      .bp3-tag .bp3-text-overflow-ellipsis, &.bp3-large .bp3-tag .bp3-text-overflow-ellipsis{
        margin-right: 0;
      }
    }
    &[class*='-intent'], &.bp3-active, &.bp3-active[class*='-intent'] {
      box-shadow: none;
      &.bp3-disabled {
        border: 1px solid rgba(41, 41, 41, 0.16);
      }
    }
    &.bp3-intent-primary {
      border: 1px solid $rt-blue2;
    }
    &.bp3-intent-success {
      border: 1px solid $green-pantone3;
    }
    &.bp3-intent-warning {
      border: 1px solid $princeton-orange;
    }
    &.bp3-intent-danger {
      border: 1px solid $rt-red3;
    }
    .bp3-tag-input-icon {
      color: $gray4;
      margin: 7px 15px 7px 0px;
      position: absolute;
      top: 0;
      & + .bp3-tag-input-values {
        margin-left: 24px;
      }
    }
    .bp3-tag-remove {
      margin-right: 0 !important;
      margin: 0;
      padding: 0;
      opacity: 1;
      &:focus {
        outline: none;
        outline-offset: 0;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .bp3-tag {
      .bp3-text-overflow-ellipsis {
        margin-right: 8px;
      }
      &.bp3-active {
        outline: none;
      }
    }
    .bp3-tag-input-values {
      margin: -1px 0;
      &:first-child .bp3-input-ghost:first-child {
        padding-left: 0;
      }
      & > * {
        margin: 0 8px 4px 0;
      }
      .bp3-tag {
        &.bp3-minimal {
            background: $bg-minimal-tags;
            color: $oxford-blue3;
            .bp3-text-overflow-ellipsis {
                color: $oxford-blue3;
                font-size: 16px;
                line-height: 24px;
            }
        }
        &.bp3-round {
            border-radius: 16px;
        }
    }
      .bp3-input-ghost {
        color: $black;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 4px;
      }
    }
    &.bp3-input.bp3-disabled {
      background: rgba(255, 255, 255, 1);
      .bp3-tag-input-values {
        .bp3-tag {
          background: $bg-disable-tag-input;
        }
      }
    }
    &.bp3-large {
      padding: 8px 0px 0px 12px;
      .bp3-tag {
        .bp3-text-overflow-ellipsis {
          margin-right: 12px;
        }
      }
      .bp3-tag-input-icon {
        margin: 13px 12px 13px 0;
        & + .bp3-tag-input-values {
          margin-left: 32px;
        }
      }
      .bp3-tag-input-values {
        .bp3-input-ghost {
          line-height: 24px;
          font-size: 16px;
        }
        > * {
          margin: 0px 12px 8px 0px;
        }
      }
      .bp3-button {
        margin: 0px;
        padding: 5px 12px;

        svg {
            color: #706F6F;

        }
    }
    }
  }
