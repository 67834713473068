.bp3-datepicker {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 0 16px 0;
  .bp3-html-select .bp3-icon {
    padding-left: 4px;
    color: $oxford-blue3;
  }
  .bp3-menu-item {
    line-height: 22px;
    padding: 5px 12px 5px 16px;
    color: $black;
    font-size: 14px;
    &:hover {
      background: #e5eaef;
      color: $black;
    }
    &:active,
    &.bp3-active {
      background: $oxford-blue3;
      color: $white;
    }
  }
  &.bp3-daterangepicker {
    .DayPicker {
      padding-right: 0;
      .bp3-datepicker-navbar {
        right: 0;
      }
      + .DayPicker {
        padding-left: 0;
        padding-right: 16px;
        .bp3-datepicker-navbar {
          right: 12px;
          left: 0;
        }
      }
    }
  }
  .bp3-daterangepicker-timepickers {
    .bp3-timepicker {
      margin: 0;
    }
    .bp3-html-select {
      .bp3-icon {
        padding-left: 0;
        right: 12px;
      }
    }
    .bp3-html-select select {
      color: $black;
      border-radius: 4px;
      padding: 0 32px 0 12px;
      &:hover {
        background: #ffffff;
        color: $black;
        border-radius: 3px;
      }
    }
    .bp3-timepicker + .bp3-timepicker {
      margin-left: 16px;
    }
  }
  .bp3-button.bp3-minimal:hover {
    background: rgba(0, 94, 184, 0.08);
  }
  .bp3-divider {
    margin: 8px 0;
  }
  .DayPicker-Day.DayPicker-Day--outside {
    color: #b2b2b2;
  }
  .bp3-datepicker-navbar {
    height: auto;
    left: 12px;
    right: 12px;
  }
  .bp3-button {
    padding: 0 4px;
    min-height: 24px;
    min-width: 24px;
    .bp3-icon {
      color: $oxford-blue3;
    }
  }

  .DayPicker-Month {
    margin: 0;
  }

  .bp3-datepicker-caption {
    margin-bottom: 0;
    select {
      height: 24px;
    }
    .bp3-icon {
      top: 4px;
    }
  }

  .bp3-html-select select,
  .bp3-select select {
    border: none;
    color: $black;
    font-weight: normal;
    padding: 0 20px 0 5px;
    text-align: center;
  }

  .bp3-html-select {
    select:hover {
      background: #e5eaef;
      color: $black;
      border-radius: 4px;
    }
  }

  .DayPicker-Weekday {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    padding-top: 0;
    color: $black;
  }
  .bp3-datepicker-day-wrapper {
    line-height: 22px;
    padding: 0;
    text-align: center;
  }
  .DayPicker {
    padding: 0 16px;
    + .bp3-divider {
      margin: 8px 12px;
    }
    &-Day {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      border-radius: 3px;
      line-height: 22px;
      &.DayPicker-Day--selected-range {
        border-radius: 0;
        background: #bfcad7;
      }
      &.DayPicker-Day--hovered-range {
        border-radius: 0;
        &.DayPicker-Day--hovered-range-end {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &:hover {
        background: #e5eaef;
        color: $black;
      }
      &:active,
      .bp3-active {
        background: $oxford-blue3;
        color: $white;
      }
      &.DayPicker-Day--isToday {
        .bp3-datepicker-day-wrapper {
          font-weight: 700;
          border: 1px solid $oxford-blue3;
          border-radius: 3px;
          color: $oxford-blue3;
        }
      }
      &.DayPicker-Day--selected {
        background-color: $rt-blue2;
        border-radius: 3px;
        .bp3-datepicker-day-wrapper {
          color: $white;
        }
        &:hover {
          background-color: $rt-blue2;
          border-radius: 3px;
        }
      }
    }
  }
  .bp3-menu + .bp3-divider {
    margin: 0;
  }
}

.bp3-daterangepicker {
  .DayPicker:first-child() {
    margin-right: 16px;
  }
  .DayPicker-Day--outside {
    visibility: unset;
  }
}

.bp3-daterangepicker-timepickers {
  margin: 8px 12px 0;
  padding-top: 8px;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
}

.bp3-timepicker .bp3-timepicker-ampm-select {
  &.bp3-html-select .bp3-icon {
    right: 12px;
  }
  select {
    padding: 0 36px 0 12px;
    border: 1px solid #e0e0e0;
  }
}
.bp3-daterangepicker
  .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
  background: #bfcad7;
}

.bp3-datepicker:focus-within {
  outline: rgba(19, 124, 189, 0.6) auto 2px !important;
  outline-offset: 2px;
}
.bp3-datepicker .bp3-html-select select:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px !important;
  outline-offset: 2px;
}
.bp3-html-select,
.bp3-select {
  margin-left: 3px;
  margin-right: 2px;
}
.bp3-datepicker .DayPicker-Day:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px !important;
  outline-offset: 2px;
}
@media only screen and (max-width: 480px) {
  .bp3-daterangepicker {
    &.bp3-datepicker .DayPicker {
      max-width: 163px;
      min-width: 0;
    }
  }
  .bp3-datepicker .bp3-html-select select,
  .bp3-datepicker .bp3-datepicker .bp3-select select {
    font-size: 12px;
    line-height: 20px;
    padding: 0px 21px 0px 0px;
    margin-right: 26px;
  }
  .bp3-datepicker .DayPicker-Weekday {
    font-size: 12px;
    line-height: 20px;
  }
  .bp3-datepicker .bp3-datepicker-day-wrapper {
    line-height: 20px;
  }
  .bp3-datepicker .DayPicker-Day {
    font-size: 12px;
  }
  .bp3-datepicker .DayPicker-Day:not(.DayPicker-Day--outside) .bp3-datepicker-day-wrapper {
    font-size: 12px;
    line-height: 20px;
  }
  .bp3-daterangepicker-timepickers .bp3-timepicker .bp3-html-select .bp3-timepicker-ampm-select {
    margin-right: 0;
  }
  .bp3-daterangepicker-timepickers {
    .bp3-timepicker .bp3-timepicker-input {
      width: 21px;
      margin-right: 2px;
    }
    .bp3-timepicker .bp3-timepicker-arrow-button {
      width: 21px;
    }
  }
  .bp3-datepicker .bp3-daterangepicker-timepickers .bp3-html-select select {
    padding: 0 4px 0 8px;
  }
}
