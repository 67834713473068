.market-information-div {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 5px 20px;
  background: white;
  border-top: 1px solid;
  border-top-width: thin;
  border-top-color: #EBEBEB;
  margin-left: -8px;
  z-index: 100;
  p {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 800;
    padding: 0;
    color: black;
  };
}
.show-button {
  position: fixed;
  right: 0;
  padding-right: 25px;
  margin-top: 5px;
}
