.metric_dashboard_row {
  &:nth-child(even) {
     background-color: #F5F5F5;
  }
  td {
    margin: 8px;
    font-size: 16px;
  }
}

.metric_dashboard_row_dark {
  &:nth-child(even) {
     background-color: #121212;
  }
  td {
    margin: 8px;
    font-size: 16px;
  }
  background: #0d1424;
}

.tablehead {
  th {
    border-bottom: 1px solid #EBEBEB;
    font-size: 16px;
    font-weight: 700;
    color: #151414;
    position: sticky;
    top: 0;
    background: white;
    padding-bottom: 8px;
    text-align: center;
  }
}

.tablehead_dark {
  th {
    border-bottom: 1px solid #EBEBEB;
    font-size: 16px;
    font-weight: 700;
    position: sticky;
    color: white;
    top: 0;
    padding-bottom: 8px;
    background: #0d1424;
    text-align: center;
  }
}


.metric_dashboard_card {
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
 }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #406acb;
  }
  padding: 20px;
  display: flexbox;
}

.metric_dashboard_card_dark {
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
 }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #406acb;
  }
  padding: 20px;
  background: #0d1424;
}

.metric_dashboard_table {
  overflow: scroll;
  padding: 0px;
  display: grid;
}

.metric_dashboard_table_dark {
  overflow: scroll;
  padding: 0px;
  color: white;
  background: #0d1424;
  display: grid;
}

.chart_title {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 12px;
  color:black;
}