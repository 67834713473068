.bp3-tooltip {
  //box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13), 0px 1px 1px 0px rgba(16, 22, 26, 0.25);
  box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.25);
  background-color: $rt-light-gray4;
  border-radius: 16px;
  align-items: center;
  text-align: center;
  color: $oxford-blue3;
  &.bp3-intent-success {
    box-shadow: none;
    .bp3-popover-arrow {
      &::before {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
      }
    }
    .bp3-popover-content {
      color: $white;
      font-weight: bold;
      background: $green-pantone3 !important;
    }
  }
  &.bp3-intent-primary {
    box-shadow: none;
    .bp3-popover-arrow {
      &::before {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
      }
    }
    .bp3-popover-content {
      color: $white;
      font-weight: bold;
      background: $oxford-blue3 !important;
    }
  }
  &.bp3-intent-warning {
    box-shadow: none;
    .bp3-popover-arrow {
      &::before {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
      }
    }
    .bp3-popover-content {
      color: $white;
      font-weight: bold;
      background: $princeton-orange3 !important;
    }
  }
  &.bp3-intent-danger {
    box-shadow: none;
    .bp3-popover-arrow {
      &::before {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
      }
    }
    .bp3-popover-content {
      color: $white;
      font-weight: bold;
      background: $rt-red3 !important;
    }
  }
  .bp3-popover-arrow {
    &::before {
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13), 1px 1px 1px 0px rgb(184, 184, 184);
    }
  }
  .bp3-popover-arrow-fill {
    fill: $rt-light-gray4;
  }
  .bp3-popover-content {
    border-radius: 16px;
    background-color: $rt-light-gray4;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: $oxford-blue3;
    padding: 5px 12px;
    line-height: 22px;
    align-items: center;
  }
  .bp3-popover-arrow-border {
    fill: transparent;
  }
}
.bp3-transition-container {
  .bp3-popover .bp3-popover-content {
    background: #f5f5f5;
  }

}

.bp3-popover[style*='transform-origin'][style*='bottom'] .bp3-popover-arrow {
  transform: translate(0, -4.5px);
}

.bp3-popover[style*='transform-origin'][style*='left'] .bp3-popover-arrow {
  transform: translate(4.5px, 0);
}

.bp3-popover[style*='transform-origin'][style*='top'] .bp3-popover-arrow {
  transform: translate(0, 4.5px);
}

.bp3-popover[style*='transform-origin'][style*='right'] .bp3-popover-arrow {
  transform: translate(-4.5px, 0);
}
