.hasBackdrop {
  background: unset;
}
.bp3-dialog {
  background: $white;
  border-radius: 8px;
  padding-bottom: 32px;
  padding-top: 32px;
  box-shadow: none;
  .bp3-dialog-header {
    border-radius: 8px 8px 0 0;
    box-shadow: unset;
    min-height: 32px;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    .bp3-heading {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      color: $rt-blue2;
    }
    .bp3-button {
      padding: 0;
      position: absolute;
      top: -19.2px;
      right: 23.2px;
      .bp3-icon {
        color: $gray10;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      &:hover {
        background: unset;
        .bp3-icon {
          color: $gray10;
        }
      }
    }
  }
  .bp3-dialog-body {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin: 0 32px;
    > * {
      margin: 16px 0;
      color: $black;
    }
    .bp3-input-group {
      margin: 24px 0 56px 0;
    }
  }
  .bp3-dialog-footer {
    margin: 0 32px;
  }
}
@media only screen and (max-width: 480px) {
  .bp3-dialog {
    min-width: 300px !important;
    max-width: 300px;
  }
  & p {
    font-size: 14px;
    line-height: 22px;
  }
}
