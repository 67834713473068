h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: #000000;
    font-size: 14px;
}

p {
    margin: 0;
    color: #565656;
    font-size: 15px;
    padding-top: 10px;
}

.history_heading h1, .requests h1{
    font-weight: 800;
    font-size: 24px;
}

.requests h1 {
    position: relative;
    margin-top: 26px;
    font-size: 16px;
}

.requests h1::before {
    position: absolute;
    content: '';
    background-color: #597697;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    top: 3px;
    left: -28px;
}

.history_txt {
    position: relative;
}

section.history {
    padding: 100px 0;
}

.history_txt::before {
    position: absolute;
    content: '';
    border: 2px solid #597697;
    width: 0px;
    height: calc(100% - 60px);
    top: 4px;
    left: -4px;
}

.requests {
    margin-left: 20px;
}

.requests h3 {
    padding-top: 5px;
    line-height: 1.6;
    font-size: 15px;
    font-weight: 400;
}
