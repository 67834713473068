.bp3-control {

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  &.bp3-large {
    font-size: 24px;
    line-height: 32px;
  }

  &.bp3-checkbox {
    color: $black;

    input:checked ~ .bp3-control-indicator:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
    }
    input:indeterminate ~ .bp3-control-indicator:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23fff'/%3E%3C/svg%3E");
    }
    .bp3-control-indicator {
      border-radius: 2px;
      border: 2px solid $rt-blue2;
    }
    input:checked ~ .bp3-control-indicator,
    input:indeterminate ~ .bp3-control-indicator {
      background-image: unset;
      &:before {
        margin: -2px;
      }
    }
    &::after {
      content: '';
      display: inline-block;
      clear: both;
    }
    input:disabled:indeterminate ~ .bp3-control-indicator {
      background: $gray10;
    }
    &.error .bp3-control-indicator {
      border-color: $rt-red1;
    }
  }

  &.bp3-switch {
    font-weight: bold;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #002c5f;
    &.bp3-large input ~ .bp3-control-indicator {
      width: 40px;
      height: 20px;
      &:before {
        box-shadow: unset;
      }
    }
    input ~ .bp3-control-indicator {
      width: 32px;
      height: 16px;
      background: #bfcad7;
      &:before {
        box-shadow: unset;
      }
    }
    .bp3-disabled {
      color: $gray10;
    }
    .bp3-control-indicator {
      min-width: 1.2em;
    }
    input:disabled ~ .bp3-control-indicator {
      background: $gray10;
    }
    input:checked ~ .bp3-switch {
      color: #002c5f;
    }
    input:checked:disabled ~ .bp3-control-indicator {
      background: $gray10;
    }
    &.bp3-align-right {
      .bp3-control-indicator {
        margin-top: 3px;
      }
    }
  }

  &.bp3-disabled {
    color: $gray10;
  }

  .bp3-control-indicator {
    box-shadow: unset;
    &::before {
      margin: -2px;
    }
  }

  &:not(.bp3-switch) input[type='checkbox']:disabled ~ .bp3-control-indicator {
    border: 2px solid $gray10;
  }
  input:disabled:checked ~ .bp3-control-indicator {
    background: $gray10;
  }

  &.error {
    input:checked ~ .bp3-control-indicator,
    input:checked:active ~ .bp3-control-indicator,
    input:indeterminate ~ .bp3-control-indicator {
      background-color: $rt-red1;
    }
  }
}
