@import "color";
// Font
$pt-font-family: 'RT_VickermanW01' !default;
$pt-font-family-roboto: 'Roboto' !default;
$pt-grid-size: 10px !default;
$pt-font-size: $pt-grid-size * 1.4 !default;
$pt-font-size-large: $pt-grid-size * 1.6 !default;
$pt-border-radius: floor($pt-grid-size / 7.5) !default;
$pt-line-height: ($pt-grid-size * 2.2) / $pt-font-size !default;
// button
$pt-button-height: 32px !default;
$pt-button-height-large: $pt-grid-size * 4.8 !default;
$button-padding: (2 * $pt-grid-size / 4) (16 * $pt-grid-size / 10) !default;
$button-padding-large: (6 * $pt-grid-size / 5) (16 * $pt-grid-size / 5) !default;
$button-box-shadow:  0px 1px 1px $gray10;
$button-box-shadow-active: inset 0px 1px 1px 0px $gray10;
$button-box-shadow-hover: 0px 1px 1px 0px $gray10, inset 0px -1px 1px 0px $gray10;
$button-intent-box-shadow: none;
$button-intent-box-shadow-active: none;
// breadcrumb
$menu-min-width: $pt-grid-size * 4 !default;
// navbar
$pt-navbar-height: $pt-grid-size * 7.6;
// input
$pt-input-height: $pt-grid-size * 3.2 !default;
$pt-input-height-large: $pt-grid-size * 4.8 !default;
// card
$pt-elevation-shadow-0: unset !default;
$pt-elevation-shadow-1: 0px 0px 13px rgba(0, 0, 0, 0.13);
$pt-elevation-shadow-2: 0px 0px 25px rgba(0, 0, 0, 0.1);
$pt-elevation-shadow-3: 0px 0px 35px rgba(0, 0, 0, 0.17);
$headings: ( "h1": (48px, 56px), "h2": (40px, 48px), "h3": (32px, 40px), "h4": (24px, 32px), "h5": (20px, 28px), "h6": (16px, 24px)) !default;
//tag input
$bg-minimal-tags: rgba(0, 94, 184, 0.08);
$bg-disable-tag-input: #B2B2B2;
//tag
$bg-minimal-primary: #BFCAD7;
$bg-minimal-success: #BFEBCF;
$bg-minimal-warning: #FFCC98;
$bg-minimal-danger: #F9C3CB;
$minimal-success: #009235;
$rt-red-danger: #BC001D !default;
//toast
$bg-primary-toast: #BFCAD7;
$bg-success-toast: #BFEBCF;
$bg-warning-toast: #FFCC98;
$bg-danger-toast: #E0878F;
$bg-icon-success-toast: #009235;
$bg-icon-warning-toast: #EA7600;
$bg-icon-danger-toast: #8B0D19;
