@import 'variable';
@import 'rt-variable';
@import './font.scss';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/select/src/blueprint-select.scss';
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";


//Modified components
@import 'button';
@import 'input';
@import 'numeric-input';
@import 'textArea';
@import 'file-input';
@import 'tag-input';
@import 'callout';
@import 'navbar';
@import 'radio';
@import 'tooltip';
@import 'checkbox';
@import 'card';
@import 'overlay';
@import 'tag';
@import 'toast';
@import 'dialog';
@import 'progress-bar';
@import 'alert';
@import 'breadcrumb';
@import 'popover';
@import 'spinner';
@import 'tree';
@import 'non-ideal-state';
@import 'control-group';
@import 'tabs';
@import 'select';
//Date Time
@import 'date-input';
@import 'date-range-input';
@import 'time-picker';
@import 'date-picker';

//table
@import 'table';

* {
  &:focus {
    outline: none;
  }
  font-family: $pt-font-family;
  font-style: normal;
  font-weight: normal;
}

.bp3-spinner {
  .bp3-spinner-animation {
    fill: transparent;
  }
  &-animation {
    animation-duration: 0.8s;
  }
  path {
    fill-opacity: 1;
  }
}

//
.bp3-breadcrumbs>li:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235C7080'/%3E%3C/svg%3E");
}

.bp3-breadcrumbs-collapsed:before {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%235C7080'%3E%3Ccircle cx='2' cy='8.03' r='2'/%3E%3Ccircle cx='14' cy='8.03' r='2'/%3E%3Ccircle cx='8' cy='8.03' r='2'/%3E%3C/g%3E%3C/svg%3E") 50% no-repeat;
}

// slide
.bp3-slider.bp3-disabled {
  opacity: 1;
}
.bp3-disabled {
  .bp3-slider-progress.bp3-intent-primary {
    background-color: $gray10;
  }
  .bp3-slider-handle {
    border-color: $gray10;
    background-color: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1) ;
    .bp3-slider-label {
      background-color:  $gray10;
      &::after {
        border-top: 8px solid #BFCAD7;
      }
    }
  }
}
.bp3-slider-axis {
  .bp3-slider-label {
    color: #706F6F;
    font-size: 13px;
    line-height: 26px;
  }
}
.bp3-slider-handle {
  border-radius: 100px;
  border: 4px solid $oxford-blue3;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-color: $white;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: none;
    background: $white;
  }
  .bp3-slider-label {
    background: $oxford-blue3;
    border-radius: 16px;
    line-height: 22px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    margin-left: 0;
    left: 0;
    padding: 5px 16px;
    bottom: 100%;
    transform: translate(calc( -50% + 4px ), -12px);
    font-size: 14px;
    color: $white;
    &::after {
      content: '';
      position: absolute;
      top: calc( 100% - 4px );
      width: 0;
      height: 0;
      left: 50%;
      transform: translate(calc( -50% + 0px ), 0);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid $oxford-blue3;
    }
  }
}

.bp3-slider-label {
  font-size: 14px;
  transform: translate(-50%, 23px);
}

.bp3-slider-track, .bp3-slider-progress {
  height: 3px;
  border-radius: 4px;
  top: 6.5px;
}

.bp3-slider-progress {
  background: $rt-light-gray2;
}

.bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
  width: 16px;
  border-radius: 50%;
}

.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end, .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
  height: 16px;
  border-radius: 50%;
}
.bp3-slider.bp3-vertical .bp3-slider-track, .bp3-slider.bp3-vertical .bp3-slider-progress {
  width: 3px;
  left: 6.5px;
}
.bp3-slider-progress.bp3-intent-primary {
  border-radius: 4px 0px 0px 4px
}
