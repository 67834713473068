.rtcmd-mbo-input {
    background: transparent;
    height: 26px;
    padding: 0 4px;
    width: 100px;
    margin: -12px -4px;
    line-height: 26px;
    box-sizing: border-box;
    border: 1px solid transparent;
    display: block;
    border-radius: 4px;
    box-sizing: content-box;

    &.enabled:hover {
        cursor: pointer;
        border-color: rgba(41, 41, 41, 0.16);
    }

    &.invalid {
        border-color: #e60d2e !important;
    }
}

input.rtcmd-mbo-input {
    &,
    &:hover,
    &.enabled:hover {
        border-color: #002c5f;
    }

    &.enabled:hover {
        cursor: default;
    }
}
