// input
.bp3-input-group {
  background: rgba(255, 255, 255, 0.16);
  .bp3-button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .bp3-input-action {
    height: 100%;
  }
  &.bp3-intent-primary {
    .bp3-input {
      border: 1px solid $rt-blue2;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border: 1px solid $rt-blue2;
      }
    }
  }
  &.bp3-intent-success {
    .bp3-input {
      border: 1px solid $green-pantone3;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border: 1px solid $green-pantone3;
      }
    }
  }
  &.bp3-intent-warning {
    .bp3-input {
      border: 1px solid $princeton-orange3;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border: 1px solid $princeton-orange3;
      }
    }
  }
  &.bp3-intent-danger {
    .bp3-input {
      border: 1px solid $rt-red1;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border: 1px solid $rt-red1;
      }
    }
  }
  .bp3-input {
    border: 1px solid $rt-light-gray2;
    background: $white;
    border-radius: 4px;
    box-shadow: unset;
    color: $black;
    line-height: $pt-line-height;
    padding: 5px 12px;
    &::placeholder {
      color: $gray4;
    }
    &:focus {
      border: 1px solid $rt-blue2;
      &::placeholder {
        color: transparent;
      }
    }
    &:disabled {
      color: $gray10;
      &::placeholder {
        color: $gray10;
      }
    }
    &:not(:focus) {
      + {
        .bp3-button.bp3-minimal {
          &:not(:hover) {
            &:not(:focus) {
              .bp3-icon {
                color: $rt-gray3;
              }
              .bp3-icon-standard {
                color: $rt-gray3;
              }
              .bp3-icon-large {
                color: $rt-gray3;
              }
            }
          }
        }
        .bp3-input-action {
          .bp3-button.bp3-minimal {
            &:not(:hover) {
              &:not(:focus) {
                .bp3-icon {
                  color: $rt-gray3;
                }
                .bp3-icon-standard {
                  color: $rt-gray3;
                }
                .bp3-icon-large {
                  color: $rt-gray3;
                }
              }
            }
            &:disabled {
              cursor: not-allowed;
              resize: none;
            }
          }
        }
      }
    }
  }

  .bp3-input-left-container {
    .bp3-button {
      line-height: 0;
      * {
        color: $white;
        line-height: $pt-line-height;
      }
    }
  }
  &.bp3-large {
    .bp3-input-action .bp3-button * {
      font-size: 16px;
    }
    .bp3-button svg {
      width: 20px;
      height: 20px;
    }
    .bp3-input-action {
      & > :not(button) {
        line-height: 24px;
        margin: 12px;
        font-size: 16px;
      }
    }
  }
  .bp3-input-action {
    * {
      margin: 0;
      color: $white;
      display: inline-block;
      vertical-align: middle;
      &:not(button) {
        margin: 5px 12px;
        color: $rt-blue2;
        font-size: 14px;
      }
    }
    & > :not(button) {
      line-height: $pt-line-height;
    }
    .bp3-button {
      line-height: 0;
      .bp3-button-text {
        line-height: $pt-line-height;
      }
      * {
        margin: 0;
        color: $white;
      }
      &.bp3-minimal {
        margin: 8px 12px;
        background: transparent;
        height: auto;
        padding: 0;
        &.no-text {
          min-width: 16px;
          min-height: 16px;
        }
        * {
          margin: 0;
          color: $gray4;
        }
      }
    }
  }
  &.bp3-sticky-panel .bp3-input-left-container .bp3-button {
    font-size: 16px;
  }
  .bp3-button {
    padding: 5px 16px;
    &:focus {
      outline: none;
    }
    &.bp3-minimal {
      .bp3-icon {
        color: $rt-gray3;
      }
    }
    &.bp3-minimal:hover {
      background: unset;
      color: $rt-gray3;
      .bp3-icon {
        color: $rt-gray3;
      }
    }
  }
  &.bp3-active {
    background: $white;
    border: 0.7px solid $rt-blue2;
    color: $rt-dark-gray2;
  }
  &.bp3-round {
    .bp3-input-action .bp3-button.no-text {
      border-radius: 0 32px 32px 0;
    }
    .bp3-input-left-container .bp3-button.no-text {
      border-radius: 32px 0 0 32px;
    }
  }
  &.bp3-large {
    .bp3-button {
      min-width: 48px;
      min-height: 48px;
    }
    .bp3-input {
      line-height: 24px;
      padding: 12px;
    }
    &.bp3-disabled {
      .bp3-button {
        cursor: not-allowed;
        resize: none;
      }
    }
    &.bp3-round {
      .bp3-button {
        border-radius: 32px 0 0 32px;
      }
      .bp3-input-action {
        .bp3-button {
          border-radius: 32px;
        }
      }
      .bp3-input-left-container {
        .bp3-button {
          border-radius: 32px;
        }
      }
    }
    .bp3-input-action {
      .bp3-button {
        margin: 0 0 0 32px;
        border-radius: 4px;
        .bp3-button-text {
          line-height: 24px;
        }
        &.bp3-minimal {
          &.no-text {
            padding: 0;
            min-width: 0;
            min-height: 0;
            margin: 14px 12px;
          }
        }
      }
    }
    .bp3-input-left-container {
      .bp3-button {
        line-height: 0;
        margin: 0 32px 0 0;
        .bp3-button-text {
          line-height: 24px;
        }
        * {
          margin: 0;
          color: $white;
        }
      }
    }
  }
  &.bp3-disabled {
    .bp3-input-action {
      svg {
        cursor: not-allowed !important;
        resize: none;
      }
    }
  }
}

// label
label.bp3-label {
  color: $black;
  font-size: 16px;
  line-height: 24px;
  .bp3-text-muted {
    color: $black;
  }
}
.bp3-form-group {
  label.bp3-label {
    margin-bottom: 4px;
  }
  .bp3-form-helper-text {
    font-size: 12px;
    line-height: 20px;
    color: $rt-gray3;
    margin-top: 8px;
    margin-left: 12px;
  }
  &.bp3-disabled {
    label.bp3-label {
      color: rgba(178, 178, 178, 1) !important;
    }
    .bp3-text-muted {
      color: rgba(178, 178, 178, 1) !important;
    }
    .bp3-form-helper-text {
      color: rgba(178, 178, 178, 1) !important;
    }
    .bp3-numeric-input {
      .bp3-input-group {
        .bp3-input {
          border: none;
        }
      }
    }
    .bp3-input-group {
      .bp3-input {
        cursor: not-allowed;
        resize: none;
        pointer-events: none;
      }
    }
    &.bp3-intent-primary,
    &.bp3-intent-success,
    &.bp3-intent-warning,
    &.bp3-intent-danger {
      .bp3-input-group {
        .bp3-input {
          border: 0.7px solid $rt-gray2;
        }
      }
    }
  }
  &.bp3-inline {
    align-items: center;
    label.bp3-label {
      line-height: 24px;
      margin: 0 5px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &.bp3-intent-primary {
    .bp3-form-helper-text {
      color: rgba(0, 44, 95, 1);
    }
  }
  &.bp3-intent-success {
    .bp3-form-helper-text {
      color: rgba(0, 152, 55, 1);
    }
  }
  &.bp3-intent-warning {
    .bp3-form-helper-text {
      color: rgba(234, 118, 0, 1);
    }
  }
  &.bp3-intent-danger {
    .bp3-form-helper-text {
      color: rgba(230, 13, 46, 1);
    }
  }
}

.bp3-inline .bp3-form-content {
  flex: 1;
}

.bp3-input-group[class*='-sticky'] {
  .bp3-button {
    min-width: 32px;
    min-height: 32px;
    padding: 0;
  }
  .bp3-input-left-container {
    .bp3-button {
      border-radius: 4px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      &.bp3-minimal {
        color: $gray4;
        color: $gray4;
        background: transparent;
        svg {
          color: $gray4;
          * {
            color: $gray4;
          }
        }
      }
    }
  }
  .bp3-input-action {
    * {
      display: flex;
    }
    .bp3-button {
      border-radius: 4px;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  &.bp3-large {
    .bp3-button {
      min-width: 48px;
      min-height: 48px;
      display: flex;
    }
  }
}
.bp3-input-group.bp3-sticky-panel .bp3-input-left-container .bp3-button {
  border-radius: 6px 0 0 6px;
}
.bp3-input-group.bp3-sticky-panel .bp3-input-action .bp3-button {
  border-radius: 0 6px 6px 0;
}

.bp3-input-group .bp3-input-action {
  .bp3-button {
    margin-left: 12px;
  }
}
.bp3-input-group .bp3-input-left-container {
  .bp3-button {
    margin: 0 12px 0 0;
  }
}

.bp3-input-group .bp3-input-left-container + .bp3-input {
  text-align: right;
}

.bp3-input-group.bp3-sticky-panel .bp3-input-left-container {
  .bp3-button {
    margin-right: 12px;
  }
  & + .bp3-input {
    text-align: left;
  }
}
.bp3-button .bp3-icon:first-child:last-child,
.bp3-button .bp3-spinner + .bp3-icon:last-child {
  margin: 0;
}
