.stp-details-container {
    padding-bottom: 32px;
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .details-content {
    .details-title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .details-table-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .details-table {
        flex: 0 0 auto;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .table-main {
    margin-top: 24px;
    width: 100%;
  }
  .table {
    width: 100%; // Ensures the table takes full width of its container
    border-collapse: collapse; // Removes the space between borders
    table-layout: fixed; // Fixes the column width
    th,
    td {
      border: 3px solid #e0e0e0;
      padding: 12px;
      overflow: hidden; // Prevents content from overflowing
    }
    th {
      width: 50%; // Sets each header to take up half the table's width
    }
  }
  .table-bottom {
    // Instead of setting the width and height of the cell, which might not work as expected:
    // Set a min-width and min-height to ensure the cell does not shrink below a certain size
    min-width: 500px;
    min-height: 600px;
    vertical-align: top;
    // If there is a need to maintain the exact width and height regardless of the content:
    // You might consider using a div inside the td with these dimensions.
    &::after {
      content: "\00a0"; // Ensures the cell is not completely empty
    }
  }
  .stp-api {
    font-size: 30px;
    font-weight: 700;
  }
  .stp-api-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 36px 0;
  }
  .table-header {
    font-size: 16px;
    font-weight: 600;
  }
  