.bp3-callout {
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: $oxford-blue;
  padding: 5px 12px;
  background: $rt-light-gray4;
  border-radius: 4px;
  .bp3-icon {
    &:first-child {
      svg {
          width: 16px;
          height: 16px;
      }
    }
  }

  &.title {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
  }

  .bp3-heading {
    color: $oxford-blue;
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px 0;
    &:last-child {
      margin-bottom: 8px;
    }
  }
  &.bp3-intent-primary {
    color: $oxford-blue3;
    background-color: rgba(0, 94, 184, 0.08);
    .bp3-heading {
      color: $oxford-blue3;
    }
    .bp3-icon {
      &:first-child {
        color: $oxford-blue3;
      }
    }
  }
  &.bp3-intent-success {
    color: $green-pantone3;
    background-color: rgba(0, 177, 64, 0.12);
    .bp3-heading {
      color: $green-pantone3;
    }
    .bp3-icon {
      &:first-child {
        color: $green-pantone3;
      }
    }
  }
  &.bp3-intent-warning {
    color: $princeton-orange3;
    background-color: rgba(234, 118, 0, 0.12);
    .bp3-heading {
      color: $princeton-orange3;
    }
    .bp3-icon {
      &:first-child {
        color: $princeton-orange3;
      }
    }
  }
  &.bp3-intent-danger {
    color: $rt-red3;
    background-color: rgba(230, 13, 46, 0.12);
    .bp3-heading {
      color: $rt-red3;
    }
    .bp3-icon {
      &:first-child {
        color: $rt-red3;
      }
    }
  }
}
.bp3-callout.bp3-callout-icon {
  padding-left: 46px;
  > .bp3-icon {
    &:first-child {
      left: 18px;
      top: 11.5px;
    }
  }
}
