.bp3-timepicker {
  .bp3-timepicker-arrow-button {
    color: $rt-blue2;
  }
}
.bp3-dateinput-popover .bp3-popover-content {
  border-radius: 8px;
}
.bp3-popover.bp3-dateinput-popover {
  .bp3-popover-content {
    margin-top: 4px;
  }
  .bp3-menu.bp3-daterangepicker-shortcuts {
    border: none;
    min-width: 130px;
  }
  .bp3-menu-item {
    font-size: 13px;
    line-height: 24px;
    color: $rt-dark-gray2;
    padding: 4px 12px;
  }
  .bp3-menu-item.bp3-intent-primary:hover,
  .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item,
  .bp3-menu-item.bp3-intent-primary.bp3-active {
    color: $white;
  }
}

.bp3-popover-wrapper
  .bp3-input-group
  .bp3-input:focus
  ~ .bp3-input-action
  .bp3-button.bp3-minimal
  .bp3-icon
  > svg:not([fill]) {
  fill: $oxford-blue3;
}

.bp3-customise-dateInput {
  .bp3-popover-wrapper {
    .bp3-popover-open {
      .bp3-input-group:nth-child(1) .bp3-input {
        border: 1px solid $oxford-blue3;
      }
    }
    .bp3-input-group {
      .bp3-input {
        color: $black;
        border: 1px solid $rt-light-gray2;
      }
      .bp3-input-action svg {
        fill: $gray4;
      }
      .bp3-input:focus {
        border: 1px solid $oxford-blue3;
        ~ .bp3-input-action svg {
          fill: $oxford-blue3;
        }
      }
      &.bp3-disabled {
        opacity: 1;
        .bp3-input {
          color: $gray10;
        }
        .bp3-input-action svg {
          fill: $gray10;
        }
      }
      &:nth-child(1) .bp3-input {
        border-radius: 4px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .bp3-dateinput-popover .bp3-popover-content .bp3-datepicker .DayPicker {
    max-width: 230px;
    min-width: 0;
  }
  .bp3-customise-dateInput {
    margin-left: 35px;
  }
}
