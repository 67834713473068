@import '~antd/dist/antd.css';
@mixin border {
  border-width: 1px 0px;
  border-color: $rt-light-gray2;
  border-style: solid;
}

.ant-table-tbody {
  & > tr {
    &.ant-table-row:hover > td {
      background: $minimal-button-background-color-hover;
    }
    &.row-disable {
      > td {
        pointer-events: none;
        a:hover {
          text-decoration: none;
        }
        &.ant-table-row-selected {
          & > td {
            background: $minimal-button-background-color-hover;
          }
        }
        & > td {
          @include border;
          line-height: 24px;
          padding: 12px 16px;
          color: $black;
          font-size: 16px;
          a {
            color: #002c5f;
            &:hover {
              text-decoration: underline;
            }
          }
          svg {
            color: #706f6f;
            width: 14px;
            height: 14px;
            &[data-icon='chevron-up'] {
              color: #002c5f;
            }
          }
        }
      }
      svg {
        color: #706f6f;
        width: 14px;
        height: 14px;
        &[data-icon='chevron-up'] {
          color: #002c5f;
        }
      }
    }
    & > th {
      @include border;
    }
  }
}

.ant-table-tbody > tr.ant-table-expanded-row > td {
  line-height: 1;
  background: $rt-light-gray2;
  border: 1px solid $rt-light-gray2;
  height: 80px;
}

.ant-table-thead {
  @include border;
  font-size: 0;
  & > tr > th {
    line-height: 24px;
    padding: 16px;
    font-weight: bold;
    background: $rt-light-gray2;
    color: $black;
    @include border();
    //border: 1px solid $rt-light-gray2;
  }
  th.ant-table-column-sort {
    background: $rt-light-gray2;
  }
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #ebebeb;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: rgba(0, 94, 184, 0.08);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: $minimal-button-background-color-hover;
}

.ant-table {
  display: block;
  .ant-radio-inner {
    border-color: $black;
    border-width: 2px;
    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  table {
    border-collapse: collapse;
  }
  &-selection {
    justify-content: center;
  }
  &-pagination {
    align-items: center;
  }
  tr.row-disable > td {
    opacity: 0.5;
  }
  &.ant-table-small .ant-table-thead > tr {
    & > th {
      padding: 12px 16px;
      line-height: 1.24;
    }
    & > td {
      padding: 6px 8px;
    }
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 2px solid $black;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $black;
    width: 10px;
    height: 10px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #002c5f;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $black;
  border-color: $black;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $black;
}

.ant-radio-inner::after {
  background-color: $black;
}

.ant-radio-checked::after {
  border: 1px solid #002c5f;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $black;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  font-size: 16px;
  line-height: 24px;
}

.ant-table-tbody .ant-table-cell {
  padding: 12px 16px;
  @include border();
  background: $white;
  color: $black;

  .ant-badge-status-success {
    background: $green-pantone3;
  }

  .ant-badge-status-text {
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  .bp3-icon-info-sign {
    margin: 2px 17px;
    color: $oxford-blue3;
  }

  span .bp3-icon-dot {
    color: $green-pantone3;
  }

  a {
    color: $oxford-blue3;
  }
  &.ant-table-row-expand-icon-cell a {
    color: $gray1;
  }

  .bp3-popover-wrapper .bp3-input-group .bp3-input {
    border-radius: 4px;
    width: 85px;
    padding: 5px 16px;
  }

  &.ant-table-column-sort {
    background: $rt-light-gray4;
  }
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-filter-trigger.active {
  color: #005eb8;
}

td.ant-table-column-sort {
  background-color: rgba(204, 204, 204, 0.2);
}

.ant-pagination {
  &-disabled .bp3-button.bp3-intent-primary:disabled {
    background: $gray10;
    color: $white;
    opacity: none;
  }
  &-item-active,
  &-item-active:focus,
  &-item-active:hover {
    color: #005eb8;
    border: none;
    background: transparent;
  }
  &-item {
    border: none;
    background: transparent;
    min-width: 28px;
    height: 26px;
  }
  &-prev,
  &-next {
    .ant-pagination-item-link {
      border: none;
    }
  }
  .ant-pagination-prev {
    margin-right: 16px;
  }
  .ant-pagination-next {
    margin-left: 16px;
  }
  .ant-pagination-item:nth-last-child(2) {
    margin-right: 0;
  }
  &.mini {
    .ant-pagination-item {
      margin: 0 4px;
      &:nth-last-child(2) {
        margin-right: 16px;
      }
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      height: auto;
    }
  }
  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      color: $black;
    }
  }
  .button-pagination {
    padding: 5px 16px;
  }
}

.ant-table-column-sorter-full {
  font-size: 0;
  line-height: 0;
}

.ant-table-cell > [class*='icon'] {
  margin-right: 16px;
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }

  &.bp3-icon-duplicate,
  &.bp3-icon-edit,
  &.bp3-icon-remove {
    color: $gray1;
    padding-right: 15px;
  }
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding: 16px;
  //padding: 12px 0 12px 0px;
}

.ant-table-column-sorter {
  color: #706f6f;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 12px;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 8px 12px;
  &.ant-table-selection-column {
    padding: 8px 0 8px 12px;
  }
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 12px;
  &.ant-table-selection-column {
    padding: 12px 0 12px 12px;
  }
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #005eb8;
}

.ant-pagination-item {
  height: 22px;
  &.ant-pagination-item-active a {
    color: #3389db;
    font-size: 16px;
    line-height: 24px;
  }
  a {
    color: $black;
  }
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 0;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: 0;
}

.ant-table-selection .ant-checkbox-wrapper {
  margin-top: 1px;
  line-height: 20px;
}

td.ant-table-selection-column .ant-checkbox-wrapper {
  margin-top: 1px;
  line-height: 20px;
  display: inline-flex;
}

.ant-table-small .ant-table-selection-column {
  width: auto;
  min-width: auto;
}

// filter and sort
.ant-table-column-title {
  font-weight: bold;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #b2b2b2;
    line-height: 1px;
  }
  .ant-btn {
    border-radius: 4px;
    color: #002c5f;
  }
  .ant-btn-primary {
    background-color: #002c5f;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: none;
    color: #ffffff;
    line-height: 1px;
    border-color: #002c5f;
    &:focus {
      outline: none;
    }
  }
  .ant-btn-sm {
    min-height: 24px;
    min-width: 24px;
    padding: 5px 16px;
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #fafafa;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: #292929;
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected > a {
    background: rgba(0, 94, 184, 0.08);
  }
  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #002c5f;
  }
}

.ant-dropdown-menu-item {
  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #002c5f;
  }
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #292929;
}

.ant-radio-inner {
  border-color: #002c5f;
}

.ant-pagination-item a {
  font-size: 14px;
  line-height: 22px;
}

@media only screen and (max-width: 480px) {
  .css-1wjen9k {
    padding: 0 !important;
  }

  .ant-pagination {
    .button-pagination {
      padding: 3px 7px;
    }
  }

  .ant-pagination.mini {
    .ant-pagination-item {
      margin: 0px 2px;
    }
    .ant-pagination-item:nth-child(2) {
      margin-left: 8px;
    }
    .ant-pagination-item:nth-last-child(2) {
      margin-right: 8px;
    }
  }

  .ant-table.ant-table-small {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: auto;
    overflow: scroll;
    tr {
      border: none;
    }
  }
  .ant-table.ant-table-small::-webkit-scrollbar {
    display: none;
  }

  .ant-table.ant-table-small {
    .ant-table-thead > tr > th {
      padding: 9px 7px 9px 8px;
    }
    .ant-table-thead > tr > th.ant-table-selection-column {
      padding: 16px;
    }
    .ant-table-thead .ant-table-column-sorters {
      padding: 9px 7px 9px 8px;
    }
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          padding: 3px 7px 3px 8px;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
    }

    .ant-table-tbody > tr.ant-table-expanded-row > td {
      height: 30px;
      padding: 7px 8px;
    }

    .ant-table-thead {
      .ant-table-cell {
        min-width: 95px;
        font-size: 14px;
        line-height: 22px;
        &.ant-table-selection-column {
          min-width: 50px;
        }
        &.ant-table-row-expand-icon-cell {
          min-width: 50px;
        }

        &.table-filter-and-sort {
          min-width: 95px;
        }
      }
      .ant-table-column-has-sorters {
        min-width: 20px;
        padding: unset !important;
      }
    }
  }
}
