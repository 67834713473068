.historical_date_range_input {
    .bp3-popover-target .bp3-control-group .bp3-input-group .bp3-input {
        color: white;
        background: black;
    }
}

.historical-daterange-picker {
    .bp3-input-group {
        width: 168px;
    }  
}